import React from "react";
import './index.scss'

const Carousel = ({ data, title, description, status, yellow, reverse = false, text, onClick }) => {
  function checkStatus(string) {
    if (string === 'VALIDATED') {
      return 'APROVADO'
    } if (string === 'DENIED') {
      return 'RECUSADO'
    } else {
      return 'PARTICIPANDO'
    }
  }
  
  return (
    <div className='content__carousel'>
      <div className='content__carousel-container'>
        <div className='content__carousel-cards__container' >
          {data.length === 0 ?
            <p>Você ainda não possui conteúdos personalizados</p>
            :
            data.map(item => {
              return (
                <>
                  <div key={item.name} className={item.img_url ? 'content__carousel-card' : 'content__carousel-card content__carousel-card--disabled'} style={{ background: item.img_url && `url(${item.img_url})` }}>
                    {yellow && <span />}
                    <header>{item?.SubCategories?.name}</header>
                    <h2>{item.name}</h2>
                    {status && <div>STATUS: {checkStatus(item.status)}</div>}
                    {description && <h3>{item.description}</h3>}
                  </div>
                </>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default Carousel