import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone'
import { Edit2, MessageSquare, ThumbsUp, UploadCloud } from 'react-feather';

import Button from '../../../../components/Button'
import Input from '../../../../components/Input'
import Card from '../../../../components/Card'
import PaymentModal from '../PaymentModal';
import { notifyError } from '../../../../helpers/errors';

function CreateToEarn({
  name,
  setName,
  image,
  setImage,
  description,
  setDescription,
  title,
  setTitle,
  nextStep
}) {
  const dispatch = useDispatch()
  const { formType } = useSelector(state => state.form)
  const [imageBlob, setImageBlob] = useState('')
  const [content, setContent] = useState('')
  const validType = [ "image/jpeg", 'image/png' ]
  const mockText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis habitasse eu."

  function uploadImage() { document.getElementById("uploader").click() }

  const handleUpload = useCallback(async(validFile) => {
    if (validFile[0]) {
      const convertBlobToBase64 = async (blob) => {
        return await blobToBase64(blob);
      }
      
      const blobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

      setImageBlob(await convertBlobToBase64(validFile[0]))
      setImage(validFile[0])
    } else {
      notifyError("Arquivo inválido")
    }
  }, [])   

  const {getRootProps, getInputProps} = useDropzone({
    accept: validType,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: handleUpload
  })  

  function chooseContent(creation) {
    if (content !== creation) {
      setContent('')
      setContent(creation)
    } else {
      setContent('')
    }
  }

  function setStep(step) {
    dispatch({ type: 'CHANGE_CREATION_STEP', payload: step })
  }

  function setPaymentModal(boolean) {
    dispatch({ type: 'CHANGE_PAYMENT_MODAL', payload: boolean })
  }

  return (
    <>
      <h1>Create to Earn</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis habitasse eu.</p>
      <div className="form__container">
        <h3>Título da missão</h3>
        <Input type={2} onChange={setName} value={name} />

        <h3>Imagem da capa</h3>
        {imageBlob ?
        <div className={`form__image form__image--${formType}`} {...getRootProps()}>
          <input type='file' id='uploader' name='uploader' {...getInputProps()} hidden/>
          <img src={imageBlob} alt="mission card" onClick={() => uploadImage()} />
        </div> :  
        <div className='form__uploader' {...getRootProps()}>
          <UploadCloud />  
          <h3>Arraste e solte seu arquivo</h3>
          <input type='file' id='uploader' name='uploader' {...getInputProps()} hidden/>
          <div className="form__btn">
            <Button type={1} onClick={() => uploadImage()} scrollLink={true}>Explorar arquivos</Button>
          </div>
        </div>}

        <div className="form__cards">
          <Card selected={content === 'vídeo'} onClick={() => chooseContent('vídeo')} title={"Vídeo"} text={mockText} image={<ThumbsUp />} />
          <Card selected={content === 'artigo'} onClick={() => chooseContent('artigo')} title={"Artigo"} text={mockText} image={<MessageSquare />} />
          <Card selected={content === 'arte'} onClick={() => chooseContent('arte')} title={"Arte"} text={mockText} image={<Edit2 />} />
        </div>        
        
        {content &&
        <>
          <h3>Descrição</h3>
          <textarea placeholder='Escreva aqui...' onChange={e => setDescription(e.target.value)} value={description}></textarea>
          
          <h3>Título {content === 'arte' ? 'da ' : 'do '}{content}</h3>
          <Input type={2} onChange={setTitle} value={title} />
        </>}

        <div className="form__btns">
          <Button type={10} onClick={() => setStep('Tipo de criação')}>
            Voltar
          </Button>
          <Button type={2} onClick={() => setPaymentModal(true)}>
            Depositar fundos
          </Button>
        </div>

      </div>
    </>
  )
}

export default CreateToEarn