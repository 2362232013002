import React from 'react'
import { X } from 'react-feather';

import './index.scss'

const Modal = ({ isVisible, title, text, close, children, small, formStep }) => {
  return (
    <div className={isVisible ? 'modal modal--visible' : 'modal'}>
      <div className={small ? "modal__content modal__content--small" : "modal__content"}>
        <header>
          <div className="modal__title">
            <h3>{title}</h3>
            <span>{text}</span>
          </div>
          {formStep ? null :
            <div className="modal__close">
              <X onClick={close} />
            </div>
          }
        </header>        
        {children}
      </div>
    </div>
  )
}

export default Modal