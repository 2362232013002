import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone'
import { Edit2, MessageSquare, ThumbsUp, UploadCloud } from 'react-feather';

import Button from '../../../../components/Button'
import Input from '../../../../components/Input'
import Card from '../../../../components/Card'
import { notifyError } from '../../../../helpers/errors';

function EngageToEarn({
  name,
  setName,
  image,
  setImage,
  description,
  setDescription,
  socialMedia,
  setSocialMedia,
  engagement,
  setEngagement,
  title,
  setTitle,
  backStep,
  nextStep
}) {
  const { formType } = useSelector(state => state.form)
  const [imageBlob, setImageBlob] = useState('')
  const [content, setContent] = useState('')
  const validType = [ "image/jpeg", 'image/png' ]
  const mockText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis habitasse eu."

  function uploadImage() { document.getElementById("uploader").click() }

  const handleUpload = useCallback(async(validFile) => {
    if (validFile[0]) {
      const convertBlobToBase64 = async (blob) => {
        return await blobToBase64(blob);
      }
      
      const blobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

      setImageBlob(await convertBlobToBase64(validFile[0]))
      setImage(validFile[0])
    } else {
      notifyError("Arquivo inválido")
    }
  }, [])   

  const {getRootProps, getInputProps} = useDropzone({
    accept: validType,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: handleUpload
  })  

  return (
    <>
      <h1>Engage to Earn</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis habitasse eu.</p>
      <div className="form__container">
        <h3>Título da missão</h3>
        <Input type={2} onChange={setName} value={name} />

        <h3>Imagem da capa</h3>
        {imageBlob ?
        <div className={`form__image form__image--${formType}`} {...getRootProps()}>
          <input type='file' id='uploader' name='uploader' {...getInputProps()} hidden/>
          <img src={imageBlob} alt="mission card" onClick={() => uploadImage()} />
        </div> :  
        <div className='form__uploader' {...getRootProps()}>
          <UploadCloud />  
          <h3>Arraste e solte seu arquivo</h3>
          <input type='file' id='uploader' name='uploader' {...getInputProps()} hidden/>
          <div className="form__btn">
            <Button type={1} onClick={() => uploadImage()} scrollLink={true}>Explorar arquivos</Button>
          </div>
        </div>}

        <h3>Selecionar rede social</h3>
        <div className="form__engagements">
          <Button style={{ marginBottom: 0 }} type={socialMedia === 'twitter' ? 2 : 10}>Twitter</Button>
          <Button style={{ marginBottom: 0 }} type={socialMedia === 'discord' ? 2 : 10}>Discord</Button>
        </div>

        <h3>Selecionar tipo de engajamento</h3>
        <div className="form__engagements">
          <Button style={{ marginBottom: 0 }} type={engagement === 'twitter' ? 2 : 10}>Entrar no servidor</Button>
          <Button style={{ marginBottom: 0 }} type={engagement === 'discord' ? 2 : 10}>Selecionar cargo</Button>
          <Button style={{ marginBottom: 0 }} type={engagement === 'discord' ? 2 : 10}>Interagir com postagem</Button>
          <Button style={{ marginBottom: 0 }} type={engagement === 'discord' ? 2 : 10}>Fazer postagem em canal de texto</Button>
          <Button style={{ marginBottom: 0 }} type={engagement === 'discord' ? 2 : 10}>Participar de evento em canal de voz</Button>
        </div>

        <h3>Descrição da missão</h3>
        <textarea placeholder='Escreva aqui...' onChange={e => setDescription(e.target.value)} value={description}></textarea>

        <div className="form__btns">
          <Button type={10} onClick={backStep}>
            Voltar
          </Button>
          <Button type={2} onClick={nextStep}>
            Depositar fundos
          </Button>
        </div>

      </div>
    </>
  )
}

export default EngageToEarn