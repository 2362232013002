import { combineReducers } from 'redux'

import appReducer from './app'
import dataVaultReducer from './dataVault'
import contentsReducer from './contents'
import embedReducer from './embed'
import formReducer from './form'
import loginReducer from './login'

const reducers = combineReducers({
  app: appReducer,
  contents: contentsReducer,
  dataVault: dataVaultReducer,
  embed: embedReducer,
  form: formReducer,
  login: loginReducer,
})

export default reducers