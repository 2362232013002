export default function app(state = {
  user: null,
  stake: null,
  stakeData: null,
  page: 'welcome',
  visitor: false,
  newUser: false,
  address: '',
  menuVisible: '',
  paymentModal: false,
}, action) {
  switch (action.type) {
    case 'CHANGE_USER':
      return { ...state, user: action.payload }
    case 'CHANGE_STAKE':
      return { ...state, stake: action.payload }
    case 'CHANGE_STAKE_DATA':
      return { ...state, stakeData: action.payload }
    case 'CHANGE_PAGE':
      return { ...state, page: action.payload }
    case 'CHANGE_VISITOR':
      return { ...state, visitor: action.payload }
    case 'CHANGE_NEW_USER':
      return { ...state, newUser: action.payload }
    case 'CHANGE_ADDRESS':
      return { ...state, address: action.payload }
    case 'CHANGE_MENU':
      return { ...state, menuVisible: action.payload }
    case 'CHANGE_PAYMENT_MODAL':
      return { ...state, paymentModal: action.payload }
    default:
      return state
  }
}
