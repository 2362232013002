import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { BounceLoader, PulseLoader } from 'react-spinners'
import _ from 'lodash'
import axios from 'axios'
import decentraland from '../../assets/icons/decentraland.png'
import { Filter, X } from 'react-feather'
import './index.scss'

import Button from '../../components/Button'
import Header from '../../components/Header'
import Carousel from '../../components/Carousel'

function Opportunities() {
  let history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [favoriteLoading, setFavoriteLoading] = useState(false)
  const [typeFilter, setTypeFilter] = useState([])
  const [types, setTypes] = useState([])
  const [levels, setLevels] = useState([])
  const [levelFilter, setLevelFilter] = useState([])
  const [favoriteFilter, setFavoriteFilter] = useState(false)
  const [filterVisible, setFilterVisible] = useState(false)
  const { user, visitor } = useSelector(state => state.app)
  const { selectedContent } = useSelector(state => state.contents)
  const contentsReducer = useSelector(state => state.contents)
  const { embedContent } = useSelector(state => state.embed)

  let contentsArray = {
    'opportunities': { title: 'Missões', subTitle: 'A única plataforma que te proporciona missões de remuneração através da tecnologia to-earn. Seja remunerado escrevendo artigos, criando artes, jogando jogos, indicando amigos, conectando suas mídias, respondendo a pesquisas e muito mais.  Aqui, sua única saída é ganhar!', description: 'Mercado de missões', subDescription: 'sub descrição missões', myTitle: 'Minhas missões' },
    'benefits': { title: 'Benefícios', subTitle: 'Acesse benefícios exclusivos para membros. Dentre eles: remuneração através da tecnologia to-earn, benefícios de consumo (camisas, NFTs, livros, etc.), ferramentas de capacitação e investimentos em produtos financeiros. A cada novo parceiro novos benefícios serão desbloqueados. ', description: 'Mercado de benefícios', subDescription: 'sub descrição benefícios', myTitle: 'Meus benefícios' },
    'education': { title: 'Jornadas', subTitle: 'A única plataforma que te proporciona oportunidades de remuneração através da tecnologia to-earn. Seja remunerado escrevendo artigos, criando artes, jogando jogos, indicando amigos, conectando suas mídias, respondendo a pesquisas e muito mais.  Aqui, sua única saída é ganhar!', description: 'Mercado de Jornadas', subDescription: 'sub descrição cursos', myTitle: 'Minhas jornadas' },
    'blog': { title: 'Blog', subTitle: 'O caminho para o futuro começa aqui!', description: 'Conteúdos', subDescription: 'sub descrição cursos', myTitle: 'Meus textos' }
  }

  let contentFilters = {
    'opportunities': [{ id: 'f9dda430-10ce-460f-99db-2d17969ddc7b', name: 'Play&Earn' }, { id: '590cae4d-a6e6-4fe6-a924-209d44e9b167', name: 'Answer&earn' }, { id: '74e9ae68-3880-4bdf-ad77-ea28348d20e9', name: 'Create&earn' }, { id: '879a15a5-468f-471a-b5db-927a6a2b2bbd', name: 'Data&Earn' }, { id: '91381bcd-39dd-4ff6-8881-841fa39ba749', name: 'Walk&Earn' }, { id: 'a8b6d3d3-f76a-4d64-ab9c-0f46c9b2c7aa', name: 'Learn&Earn' }, { id: 'f9dda430-a0ce-460f-99db-2d17969ddc7b', name: 'Write&Earn' }],
    'benefits': [{ id: "104d57d5-9276-4980-9ff1-b2859f0e21fe", name: "Airdrop" }],
    'education': [{ id: '22a92454-dbf7-471b-8169-cc95680084b1', name: 'E-Books' }, { id: 'c53c2faf-c841-4fe1-a293-581aca85c2a1', name: 'Reviews' }, { id: 'e48a7987-c697-4c9c-9917-2fa12b22bef4', name: 'Tutoriais' }, { id: 'ee3b4ed4-0af1-4e66-8c9c-5ee3d4c4d3fe', name: 'Vídeos' }],
    'blog': [{ id: "baac9e21-1bb9-4324-b72f-5a972b2d9775", name: "Games" }, { id: "9af3b239-bb5a-4a8a-bdd9-9074e1636502", name: "Tecnologia" }, { id: "155432a8-d5e7-4d34-a3a6-3da1e4297cc3", name: "NFT" }, { id: "30154946-bb50-479e-9580-502dc9db1e66", name: "Web3" }, { id: "4d557c59-6382-45a6-9252-4629b50b52bc", name: "Blockchain" }, { id: "678daa00-58fc-4c3e-a389-1d3edce9f63f", name: "DAO" }]
  }

  useEffect(() => {
    setLoading(true)
    axios.get('/content-card/all')
      .then(response => {
        let { data } = response
        let education = [], benefits = [], opportunities = []
        data.map(item => {
          let category = item.Category
          if (category.name === 'Educação') {
            education.push(item)
          }
          else if (category.name === 'Oportunidades') {
            opportunities.push(item)
          }
          else {
            benefits.push(item)
          }
        })
        dispatch({ type: 'CHANGE_BENEFITS', payload: benefits })
        dispatch({ type: 'CHANGE_OPPORTUNITIES', payload: opportunities })
        dispatch({ type: 'CHANGE_EDUCATION', payload: education })
      }).catch(e => {
        console.log(e)
      })

    axios.get('/sub-categories/all')
      .then(response => {
        setTypes(response.data)
      }).catch(e => {
        console.log(e)
      })

    axios.get('/levels/all')
      .then(response => {
        setLevels(response.data)
        setLoading(false)
      }).catch(e => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    setLevelFilter([])
    setFavoriteFilter(false)
    setTypeFilter([])
  }, [selectedContent])

  function filterCards() {
    let cards = contentsReducer[selectedContent]
    let favoriteCards = []
    let favoriteResult = [], typeResult = [], levelResult = [], result = []

    if (visitor) {
      return cards
    }

    if (!cards) {
      return []
    }

    user.cardList.map(item => {
      if (item.favorite) {
        favoriteCards.push(item.id)
      }
    })

    if (favoriteFilter) {
      favoriteResult = cards.filter((a) => {
        if (favoriteCards.includes(a.id)) {
          return a
        }
      })
    }

    if (!_.isEmpty(typeFilter)) {
      typeFilter.map(item => {
        cards.map(card => {
          if (card.SubCategories?.id === item) {
            typeResult.push(card)
          }
        })
      })
    }

    if (!_.isEmpty(levelFilter)) {
      levelFilter.map(item => {
        cards.map(card => {
          if (card.Levels.id === item) {
            levelResult.push(card)
          }
        })
      })
    }

    result = [...favoriteResult, ...typeResult, ...levelResult]

    result = result.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.id === value.id
      ))
    )

    if (!_.isEmpty(favoriteResult)) {
      let typeResult = [], levelResult = []

      //only favorite
      if (_.isEmpty(typeFilter) && _.isEmpty(levelFilter)) {
        return favoriteResult
      }

      //favorite and type
      else if (!_.isEmpty(typeFilter) && _.isEmpty(levelFilter)) {
        typeFilter.map(item => {
          favoriteResult.map(card => {
            if (card.SubCategories?.id === item) {
              typeResult.push(card)
            }
          })
        })

        return typeResult
      }

      //favorite and level
      else if (_.isEmpty(typeFilter) && !_.isEmpty(levelFilter)) {
        levelFilter.map(item => {
          favoriteResult.map(card => {
            if (card.Levels?.id === item) {
              levelResult.push(card)
            }
          })
        })

        return levelResult
      }

      else {
        //everything
        typeFilter.map(item => {
          favoriteResult.map(card => {
            if (card.SubCategories?.id === item) {
              typeResult.push(card)
            }
          })
        })

        levelFilter.map(item => {
          typeResult.map(card => {
            // console.log({ card, item })
            if (card.Levels?.id === item) {
              levelResult.push(card)
            }
          })
        })

        return levelResult
      }
    }

    if (_.isEmpty(result) && !favoriteFilter && _.isEmpty(typeFilter) && _.isEmpty(levelFilter)) {
      return cards
    }

    return result
  }

  function handleFilters(type, value) {
    if (type === 'type') {
      if (typeFilter.includes(value)) {
        let remove = typeFilter.filter((id) => id !== value)
        setTypeFilter(remove)
      }
      else {
        let add = [...typeFilter]
        add.push(value)
        setTypeFilter(add)
      }
    }
    else {
      if (levelFilter.includes(value)) {
        let remove = levelFilter.filter((id) => id !== value)
        setLevelFilter(remove)
      }
      else {
        let add = [...levelFilter]
        add.push(value)
        setLevelFilter(add)
      }
    }
  }

  function favoriteCard(id) {
    setFavoriteLoading(true)
    axios.post('/content-card/favorite/add', { contentCardId: id })
      .then(response => {
        fetchUserData()
      }).catch(e => {
        console.log(e)
      })
  }

  function unfavoriteCard(id) {
    setFavoriteLoading(true)
    axios.delete(`/content-card/favorite/remove/${id}`)
      .then(response => {
        fetchUserData()
      }).catch(e => {
        console.log(e)
      })
  }

  async function fetchUserData() {
    let userData = await axios.get(`/user/find/address/${user.address}`)
    dispatch({ type: 'CHANGE_USER', payload: userData.data })
    localStorage.setItem('user', JSON.stringify(userData.data))
    setFavoriteLoading(false)
  }

  function chooseContent(id, link, open) {
    if (open) {
      window.open(link, '_blank')
    }

    axios.post('content-card/list/add', { contentCardId: id })
      .then(response => {
        fetchUserData()
      }).catch(e => {
        console.log(e)
      })
  }

  function isFavorite(id) {
    let bool = false

    if (visitor) {
      return false
    }

    bool = user.cardList?.filter((a) => {
      if (a.id === id && a.favorite) {
        return true
      }
    })

    if (_.isEmpty(bool)) {
      return false
    }
    return bool
  }

  function returnMyContent() {
    let cards = contentsReducer[selectedContent]
    if (!cards) {
      return []
    }
    
    if (visitor) {
      return []
    }
    let myContent = []

    cards.map(item => {
      user.cardList?.filter((a) => {
        // console.log({a,item})
        if (a.id === item.id && a.selected) {
          myContent.push(a)
        }
      })
    })

    return myContent
  }

  function setEmbedContent(content) {
    dispatch({ type: 'CHANGE_EMBED_CONTENT', payload: content })
  }

  function toggleFilters() {
    filterVisible ? setFilterVisible(false) : setFilterVisible(true)
  }

  function handleInterest(item) {

    if (visitor) {
      return
    }

    if (selectedContent === 'education') {
      setEmbedContent(item)
      dispatch({ type: 'CHANGE_PAGE', payload: 'education' })
      history.push(`/education/${item.id}`)
      chooseContent(item.id, item.link, false)   
    }
    else {
      if (!visitor) {
        if (selectedContent === 'benefits') {
          history.push(`/airdrops/${item.id}`)
          chooseContent(item.id, item.link, false)
        }
        else {
          chooseContent(item.id, item.link, true)
        }
      }

    }
  }

  return (
    <div className='opportunities'>
      <Header title={contentsArray[selectedContent].title} subTitle={contentsArray[selectedContent].subTitle} />
      <div className='opportunities__my'>
        {contentsArray[selectedContent].myTitle}
        <Carousel data={returnMyContent()} yellow={true} status={true} />
      </div>
      
      {/* <div className='opportunities__container'>
        <div className='opportunities__header'>
          <div className="opportunities__button">
            {filterVisible ?
              <X size={26} onClick={() => toggleFilters()} /> :
              <Filter size={26} onClick={() => toggleFilters()} />}
          </div>
          {contentsArray[selectedContent].description}
          <span>{contentsArray[selectedContent].subDescription}</span>
        </div>
        {
          loading ?
            <div className='opportunities__data__loading'>
              <BounceLoader color='#FFAE00' />
            </div>
            :
            <div className={filterVisible ? 'opportunities__data opportunities__data--filter' : 'opportunities__data'}>
              <div className={filterVisible ? 'filter filter--visible' : 'filter'}>
                Filters
                <div className='filter__type'>
                  <span>POR FAVORITO</span>
                  <div className='filter__button' onClick={() => setFavoriteFilter(!favoriteFilter)}>
                    <Button type={5} style={favoriteFilter ? { backgroundColor: '#FFAE00', borderColor: '#FFAE00' } : {}}>Favorito</Button>
                  </div>
                </div>
                <div className='filter__type'>
                  <span>POR TIPO</span>
                  {_.orderBy(contentFilters[selectedContent], 'name', 'asc').map(item => {
                    return (
                      <div className='filter__button' onClick={() => handleFilters('type', item.id)}>
                        <Button type={5} style={typeFilter.includes(item.id) ? { backgroundColor: '#FFAE00', borderColor: '#FFAE00' } : {}}>{item.name}</Button>
                      </div>
                    )
                  })}
                </div>
                <div className='filter__type'>
                  <span>POR NÍVEL</span>
                  {_.orderBy(levels, ['name'], 'asc').map(item => {
                    return (
                      <div className='filter__button' onClick={() => handleFilters('level', item.id)}>
                        <Button type={5} style={levelFilter.includes(item.id) ? { backgroundColor: '#FFAE00', borderColor: '#FFAE00' } : {}}>{item.name}</Button>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='opportunities__card-container'>
                {filterCards().map(item => {
                  return (
                    <div className='opportunities__card' style={{ backgroundImage: `url(${item.img_url})` }}>
                      <header>{item.name}</header>
                      <main>{item.description}</main>
                      <div className='opportunities__card-description'>
                        <header>{item.info}</header>
                        {item?.SubCategories?.name}, Level {item?.Levels?.name}
                      </div>
                      <footer>
                        <div className={visitor ? 'opportunities__card-buttons opportunities__card-buttons--disabled' : 'opportunities__card-buttons'}>
                          <div className='opportunities__card-left__button'><Button type={5} style={{ padding: '2px 0', width: '100%', marginBottom: '0' }} onClick={() => handleInterest(item)} >Tenho interesse</Button></div>
                          <div className='opportunities__card-right__button'><Button type={6} style={{ padding: '2px 0', width: '100%', marginBottom: '0' }} onClick={() => { !visitor && (isFavorite(item.id) ? unfavoriteCard(item.id) : favoriteCard(item.id)) }}>
                            {favoriteLoading ?
                              <PulseLoader size={10} color='white' />
                              :
                              isFavorite(item.id) ? 'Desfavoritar' : 'Favoritar'
                            }
                          </Button></div>

                        </div>
                      </footer>
                      <span className='opportunities__card-background' />
                    </div>
                  )
                })}
              </div>
            </div>
        }
      </div> */}

    </div >
  )
}

export default Opportunities;