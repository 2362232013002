import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom'
import { getGreeting } from '../../constants/others'
import { User, Menu } from 'react-feather'
import './index.scss'

import Button from "../Button"
import duxLogoBlack from '../../assets/logos/duxLogoBlack.svg'

function Header({ greeting = false, title, subTitle, data, document, mobile }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { menuVisible, user, visitor } = useSelector(state => state.app)

  function handleClick(url, selectContent = false) {
    dispatch({ type: 'CHANGE_PAGE', payload: url })
    history.push(`/${url}`)

    if (selectContent) {
      dispatch({ type: 'CHANGE_SELECTED_CONTENT', payload: selectContent })
    }
  }

  function openMenu(content) {
    dispatch({ type: 'CHANGE_MENU', payload: content })
  }

  return (
    <div className='header'>
      {document ?
        <div className="header__document">
          <img className='header__logo' src={duxLogoBlack} alt='dux logo' onClick={() => handleClick('welcome')} />
          <div className="header__nav">
            <h6 onClick={() => handleClick('document/terms')}>Termos de Uso</h6>
            <h6 onClick={() => handleClick('document/privacy')}>Política de Privacidade</h6>
            <h6 onClick={() => handleClick((user || visitor) ? 'welcome' : 'login')}>Voltar</h6>
          </div>
        </div> :
        mobile ?
          <div className="header__mobile">
            <img className='header__logo' src={duxLogoBlack} alt='dux logo' onClick={() => handleClick('welcome')} />
            <div className="header__nav">
              <div className="header__icon">
                <Menu onClick={() => openMenu('menu')} />
                {/* Menu */}
              </div>
              <div className="header__icon">
                <User onClick={() => openMenu('profile')} />
                {/* Perfil */}
              </div>
            </div>
          </div>
          :
          <div className='header__name'>
            {greeting ? `${getGreeting()}, ${title}` : title}
            <span>{subTitle}</span>
          </div>
      }
      {/* <div className='header__balance'>
        <div className='header__balance-logo'>
          <img src={duxLogoWhite} alt='dux white logo' />
        </div>
        DUX
        <span>DUX</span>
        <div className='header__balance-value'>
          $2513<span>.23</span>
        </div>
      </div> */}
    </div>
  )
}

export default Header;