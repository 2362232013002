import standardBadge from '../../assets/badge.svg'

import './index.scss'

const Badge = ({ imageUrl, title, empty=false, selected, onClick }) => {
  return (
    <div onClick={onClick} className={selected ? 'badge badge--selected' : 'badge'}>
      {empty ? 
      <span className="badge-empty" />
      : imageUrl ?
        <img title={title} src={imageUrl} alt='badge pic' /> :
        <img title={title} src={standardBadge} alt='badge pic' />
      }
    </div>
  )
}

export default Badge