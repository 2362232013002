import { useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners'
import { CSSProperties } from "react";
import './index.scss'

import Button from '../../../../components/Button'
import Badge from '../../../../components/Badge'

function Review({ 
  name, 
  category, 
  subcategory,
  type,
  level,
  xp,
  token,
  badges,
  image,
  description,
  loading,
  onClickLeftBtn,
  onClickRightBtn
}) {
  const { formType, missions } = useSelector(state => state.form)

  return (
    <div className={`review__container review__container--${formType}`}>
      <div className='review'>
        <div className="review__field">
          <h3>Título da missão</h3>
          <p>{name}</p>
        </div>
        {formType === 'missão' ? <>
        {/* <div className="review__field">
          <h3>Categoria</h3>
          <p>{category}</p>
        </div> */}
        <div className="review__field">
          <h3>Categoria</h3>
          <p>{subcategory}</p>
        </div>
        {/* <div className="review__field">
          <h3>Link do material</h3>
          <p className='review__url'>{url}</p>
        </div> */}
        </> : 
        null
        // <>
        // <div className="review__field">
        //   <h3>Tipo</h3>
        //   <p>{type}</p>
        // </div></>
        }
        {formType === 'missão' || formType === 'jornada' ? 
        <div className="review__field">
          <h3>Level</h3>
          <p>{level}</p>
        </div> : null}
        {formType === 'missão' ? <>
        <div className="review__field">
          <h3>Recompensas</h3>
          {xp ? <p>{xp} XP</p> : null}
          {token ? <p>{token} Token</p> : null}
          <div className="review__badges">
            {badges ? badges.map(badge => {
            return (
              <Badge title={badge.name} />
            )}) : null}
          </div>
        </div></> : null}
        <div className="review__field">
          <h3>Imagem de capa</h3>
          {formType === 'badge' ?
            <img className={`review__image review__image--${formType}`} src={image} alt="mission card" />
          :
          <div className={`review__mission review__mission--${formType}`}>
            <div className='carousel__card' style={{ backgroundImage: `url(${image})` }}>
              {/* <span /> */}
              {/* <header>{name}</header> */}
              {/* <h3>{description}</h3> */}
            </div>
          </div>}
        </div>
        <div className="review__field">
          <h3>Descrição</h3>
          <p>{description}</p>
        </div>
        <div className="review__btns">
          <Button onClick={onClickLeftBtn} type={10}>Editar criação</Button>
          <Button onClick={onClickRightBtn} type={2}>{loading ? <PulseLoader size={10} /> : 'Finalizar criação'}</Button>
        </div>
      </div>

      {formType === 'jornada' && <div className="review__missions">
        {missions.map(mission => {
          return (
            <div className="review__mission">
              <h2>{missions.indexOf(mission)+1}° passo</h2>
              <div className='carousel__card' style={{ backgroundImage: `url(${mission.img_url})` }}>
                <span />
                <header>{mission.name}</header>
                <h3>{mission.description}</h3>
              </div>
              {missions.length > missions.indexOf(mission)+1 &&
                <div className="review__mission-dots">
                  <PulseLoader size={10} />
                </div>
              }
            </div>
          )
        })}
      </div>}
    </div>
  )
}

export default Review