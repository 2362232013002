import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone'
import { notifyError } from '../../helpers/errors';
import { UploadCloud } from 'react-feather'

import Header from '../../components/Header'
import Input from '../../components/Input'
import Button from '../../components/Button'

import './index.scss'

function Mission() {
  const { selectedMission } = useSelector(state => state.embed)
  const { opportunities } = useSelector(state => state.contents)
  const [email, setEmail] = useState('')
  const [evm, setEvm] = useState('')
  const [image, setImage] = useState('')
  const [imageBlob, setImageBlob] = useState('')
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [chosenMission, setChosenMission] = useState([])
  const [customInfo, setCustomInfo] = useState('')
  const blockchainQuestions = [
    { title: 'Qual a probabilidade de você investir seu dinheiro em um projeto focado em um tema de grande interesse pessoal seu, porem sem foco em rentabilidade do projeto?', options: ['0%', '25%', '50%', '75%', '100%'] },
    { title: 'Qual a probabilidade de você investir seu tempo em um projeto focado em um tema de grande interesse pessoal seu, porem sem foco em rentabilidade do projeto?', options: ['0%', '25%', '50%', '75%', '100%'] },
    { title: 'Imagine que um projeto em que você investiu te deu retorno de 500%, porém se encontra com  varias etapas do roadmap em atraso...Como você se posiciona?', options: ['Reinvistiria tudo', 'Reinvistiria metade', 'Reinvestiria o valor investido inicialmente', 'Não Reinvestiria e passaria o dinheiro para sua reserva de valor', 'Não Reinvestiria e passaria o dinheiro para  um novo projeto'] },
    { title: 'Qual dentre as seguintes formas de geração de renda é seu foco atual em WEB3?', options: ['Trading', 'Jogador', 'Empreender projetos web3', 'Freelancer (Jobs)', 'Não tenho foco em nenhuma das formas de renda citadas'] },
    { title: 'Qual dentre os seguintes comportamentos mais se assemelha com o seu ao comprar um token?', options: ['Fazer Hold', 'Fazer Trade', 'Diversificar portfolio', 'Governança de projetos associados ao token', 'Não compro tokens'] },
    { title: 'Ao se dedicar a um projeto você espera reconhecimento principalmente na forma de...', options: ['NFTs Exclusivos', 'Tokens', 'Cargos na comunidade do projeto (DNA, Community Manager)', 'Benefícios na comunidade do projeto', 'Governança'] },
    { title: 'A partir de qual dentre os seguintes valores e USD você considera um investimento de grande porte?', options: ['$100', '$1.000', '$10.000', '$50.000', '$100.000'] },
    { title: 'Qual o seu nível de interesse em aprender sobre o mercado cripto e web3 em geral?', options: ['Indiferente', 'Tenho interesse mas não estudo regularmente', 'Tenho interesse mas estudo ocasionalmente', 'Tenho bastante interesse e estudo regularmente', 'Tenho muito interesse e estudo sempre que posso, continuamente.'] },
    { title: 'Qual dessas categorias mais se alinha com seu foco ao estudar projetos?', options: ['Análise Fundamentalista', 'Análise Gráfica', 'Calls de influenciadores', 'Intuição', 'Projetos com temáticas de interesse pessoal'] },
    { title: 'Qual destas combinações de Risco versus Recompensa mais se encaixa nas suas escolhas ao investir?', options: ['Extremo risco vs Extrema recompensa', 'Alto risco vs Alta recompensa', 'Médio risco vs Média recompensa', 'Baixo risco vs Baixa recompensa', 'Não invisto'] },
    { title: 'Um projeto fornece as oportunidades a seguir para você participar e receber em criptoativos. Qual delas seria a sua principal escolha?', options: ['Criação de conteúdos', 'Responder pesquisas', 'Jogar', 'Participação ativa na comunidade do projeto', 'Não me interessaria por nenhuma das atividades citadas'] },
    { title: 'Qual é o mínimo de remuneração diária em USD necessária para você se dedicar diariamente a um projeto no seu tempo livre?', options: ['$0,5', '$1', '$2', '$5', '$10 ou mais'] },
    { title: 'Qual é o mínimo de remuneração diária em USD necessária para você se dedicar a um projeto como sua atividade principal?', options: ['$1', '$5', '$10', '$25', '$50 ou mais'] },
    { title: 'Qual dessas classes de criptoativos você tem maior experiência?', options: ['Criptomoedas', 'PFPs', 'Itens e personagens em jogos web3', 'POAPs', 'Não tenho familiaridade com nenhuma dessas classes'] },
    { title: 'Qual seu nível de interesse em fazer parte de uma comunidade WEB3?', options: ['Não tenho interesse', 'Baixo', 'Médio', 'Alto', 'Meu principal interesse são comunidades web3'] },
    { title: 'Qual seu nível de interesse no surgimento de grandes redes sociais na web3?', options: ['Não tenho interesse e acredito que esse surgimento seja negativo', 'Baixo', 'Médio', 'Alto', 'Indiferente'] },
    { title: 'Qual seu nível de interesse em Aplicativos web3 que aceitem Pix como um dos caminhos de entrada e saída de recursos?', options: ['Não tenho interesse', 'Baixo', 'Médio', 'Alto', 'Indiferente'] },
    { title: 'Qual seu nível de interesse em cartões de debito que possam ser recarregados com criptomoedas?  ', options: ['Não tenho interesse', 'Baixo', 'Médio', 'Alto', 'Indiferente'] },
    { title: 'Qual seu nível de interesse em Aplicativos de compras, reservas e assinaturas que utilizem a tecnologia web3 para segurança e pagamentos?', options: ['Não tenho interesse', 'Baixo', 'Médio', 'Alto', 'Indiferente'] },
    { title: 'Qual das seguintes previsões mais se alinha com sua expectativa para projetos web3 no futuro?', options: ['Abandono do mercado web3', 'Redução do mercado web3', 'Estagnação do mercado web3', 'Expansão do mercado web3', 'Segmento web3 como principal mercado tecnológico'] },
  ]

  function uploadImage() { document.getElementById("uploader").click() }

  const handleUpload = useCallback(async(validFile) => {
    if (validFile[0]) {
      const convertBlobToBase64 = async (blob) => {
        return await blobToBase64(blob);
      }
      
      const blobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

      setImageBlob(await convertBlobToBase64(validFile[0]))
      setImage(validFile[0])
    } else {
      notifyError("Arquivo de imagem inválido")
    }
  }, [])   

  const {getRootProps, getInputProps} = useDropzone({
    accept: "image/*",
    multiple: chosenMission?.multipleFiles,
    noClick: true,
    noKeyboard: true,
    onDrop: handleUpload
  })    

  const blockchainForm = 
  <>
    <h3>Nome COMPLETO</h3>
    <Input style={{ marginBottom: '0' }} type={2} placeholder='Nome completo' onChange={setEvm} value={evm} />
    <h3>Carteira BUSD BEP20</h3>
    <Input style={{ marginBottom: '0' }} type={2} placeholder='Carteira BUSD BEP20' onChange={setEvm} value={evm} />
    <h3>Discord ID <span>(Necessário estar conectado ao discord.gg/wearedux)</span></h3>
    <Input style={{ marginBottom: '0' }} type={2} placeholder='Discord ID' onChange={setEvm} value={evm} />
    {blockchainQuestions.map(question => {
    return ( <>
      <h3>{question.title}</h3>
      <div className='mission__radios'>
        {question.options?.map((option, index) => (
        <div style={{margin: "8px 0"}} className='mission__radio'>
          <input type="radio" id={index} name="blockchain" value={option} />
          <label for="blockchain">{option}</label>
        </div>))}
      </div></>
    )})}
    <h3>Quanto tempo você levou em média (minutos) para preencher todas as questões?</h3>
    <Input style={{ marginBottom: '0' }} type={2} placeholder='Tempo médio (minutos)' onChange={setEvm} value={evm} />
    <h3>Insira Feedbacks sobre a pesquisa!</h3>
    <Input style={{ marginBottom: '0' }} type={2} placeholder='Insira seu feedback aqui' onChange={setEvm} value={evm} />
  </>

  let originalMissions = [
    { id: '0eb54402-5969-4522-8770-e824189d8a9f', name: 'Crie seu .gif da comunidade DUX', reward: 'U$5', task: <><p><strong>É hora de despertarmos os (ainda mais) artistas de plantão!</strong></p><p><strong>Para enfrentar este desafio o participante deve enviar um GIF exclusivo e criativo, que exprima seus sentimentos pela DUX!</strong></p><p>Haverá uma breve avaliação do GIF!</p></>, uploader: true, 
    customFields:      
    <>
      <h3>Conte um pouco mais sobre o seu GIF!</h3>
      <textarea placeholder='Nome e história do GIF' onChange={e => setCustomInfo(e.target.value)} value={customInfo}></textarea>
    </>},
    { id: '1678ea44-c3d2-4987-a334-ff9673277f30', name: 'Crie seu emoji da comunidade DUX', reward: 'U$2', task: <><p><strong>É hora de despertarmos os artistas de plantão!</strong></p><p><strong>Para enfrentar este desafio o participante deve enviar um EMOJI exclusivo e criativo, que exprima seus sentimentos pela DUX!</strong></p><p>Haverá uma breve avaliação do EMOJI!</p></>, uploader: true,
    customFields:      
    <>
      <h3>Conte um pouco mais sobre o seu EMOJI!</h3>
      <textarea placeholder='Nome e história do EMOJI' onChange={e => setCustomInfo(e.target.value)} value={customInfo}></textarea>
    </>},
    { id: '4eb046dc-2e98-4a5f-8282-4ee412bb6993', name: 'Compartilhe sua experiencia com a DUX', reward: 'U$1', task: <><p><strong>Em homenagem ao lançamento da plataforma que representa a consolidação das nossas trajetórias a DUX quer conhecer você um pouco melhor!</strong></p><p><strong>A missão é simples: Compartilhe um momento emblemático seu, seja o time que usou em Axie Infinity, um momento na comunidade DUX ou qualquer acontecimento que teve valor para você!</strong></p><p>Haverá uma breve avaliação das imagens!</p></>, multipleFiles: true,
    customFields:      
    image ?
    <div className={'form__image'} {...getRootProps()}>
      <input type='file' id='uploader' name='uploader' {...getInputProps()} hidden/>
      <img src={imageBlob} alt="mission card" onClick={() => uploadImage()} />  
    </div> : <>
    <h3>Compartilhe aqui SEU momento DUX</h3>
    <div className='form__uploader' style={{ marginBottom: 0 }} {...getRootProps()}>
      <UploadCloud />  
      <h3>Arraste e solte seu arquivo</h3>
      <input type='file' id='uploader' name='uploader' {...getInputProps()} hidden/>
      <div className="form__btn">
        <Button style={{ marginTop: '12px' }} type={1} onClick={() => uploadImage()} scrollLink={true}>Explorar arquivos</Button>
      </div>
    </div></>},
    { id: '95c5c589-bd99-4dbf-a4ad-1839e4fbea2f', name: 'Quem é voce na blockchain', reward: 'U$2', task: <><p><strong>Essa é a primeira parte de uma Trilha de Oportunidades!</strong></p><p>A pesquisa adiante tem por finalidade entender o perfil de vocês. Basta concluir e receber a recompensa!</p><p>Logo as próximas Oportunidades da Trilha serão anunciadas!</p></>,
    customFields: 
    <>
      {blockchainForm}
    </>
    },
    { id: "db2972ff-13b2-4b83-af73-3795aec680d0", name: 'Genopets', task: <><p>Genopets é o primeiro projeto de Metaverso que reúne três modalidades de jogos como Move-to-Earn (M2E), Free-to-Play(F2P) e Massive Multiplayer Online Role Playing Game (MMORPG), incentivando um estilo de vida mais saudável e rentável para o usuário, inspirado em modelos de jogos: "Nursing" como os famosos Tamagotchis ou “bichos virtuais” febre dos anos 90; RPG Battle como os jogos clássicos da franquia Pokémon; Farm e Craft como os jogos Animal Crossing e de outros clássicos como Harvest Moon e Colheita Feliz. Além desses três modelos, ele inova ao integrar a isso tudo o modelo M2E, onde recompensas são obtidas de acordo com a movimentação do jogador ao longo de seu dia.</p><p>A partir do preenchimento, o participante receberá o <strong>CÓDIGO DE ACESSO</strong> ao projeto via e-mail.</p></> },
    { id: 'e7d77b5a-8d4e-4c43-853f-8de4e013062e', name: 'Crie a próxima quest da DUX', reward: 'U$1', task: <><p><strong>Acabou a brincadeira! Vamos por as mãos na massa!</strong></p><p><strong>Crie a próxima oportunidade para a DUX e receba a recompensa!</strong></p></>,
    customFields:      
    <>
      <h3>Descrição da sua oportunidade</h3>
      <textarea placeholder='De que modo você deseja contribuir para a construção da DUX e receber recompensas por isso? Seja generoso nos detalhes!' onChange={e => setCustomInfo(e.target.value)} value={customInfo}></textarea>
    </>},
  ] 

  useEffect(() => {
    window.scrollTo(0, 0)
    let filteredCustomMission
    let filteredOriginalMission = originalMissions.filter((mission) => {
      if (mission.id === selectedMission) {
        return mission
      } else {
        filteredCustomMission = opportunities.filter((mission) => {
          return mission.id === selectedMission
        })
      }
    })
    filteredOriginalMission.length > 0 ? setChosenMission(filteredOriginalMission[0]) : setChosenMission(filteredCustomMission[0])
  }, [])

  return (
    <div className='mission'>
      <Header title={'Missão Lumx Studios Web3 Pro'} subTitle={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis habitasse eu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis habitasse eu.'} />
      <Header title={chosenMission?.name} />
      <div className="mission__task">
        <p>O presente formulário foi elaborado em conformidade com a <a target='_blank' rel='noreferrer' href='http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm'>Lei Geral de Proteção de Dados Pessoais</a> - <a target='_blank' rel='noreferrer' href='https://juristas.com.br/tag/LGPD'>LGPD</a>. Consoante ao artigo 5º inciso XII da Lei 13.709, viabilizando a manifestação livre, informada e inequívoca, pela qual o titular/ responsável concorda com o tratamento de seus dados pessoais para as finalidades aqui indicadas.</p>
        {chosenMission?.task}
        {chosenMission?.reward ? <p><strong>Recompensa: {chosenMission.reward}</strong></p> : null}
        <p>Nossa comunidade se concentra em: <a target='_blank' rel='noreferrer' href='https://bit.ly/3DQKj7g'>DUX</a></p>
        <h3>E-mail</h3>
        <Input style={{ marginBottom: '0' }} type={2} placeholder='Email' onChange={setEmail} value={email} />
        <h3>Carteira EVM</h3>
        <Input style={{ marginBottom: '0' }} type={2} placeholder='Carteira EVM' onChange={setEvm} value={evm} />
        
        {chosenMission?.uploader ?
        image ?
        <div className={'form__image'} {...getRootProps()}>
          <input type='file' id='uploader' name='uploader' {...getInputProps()} hidden/>
          <img src={imageBlob} alt="mission card" onClick={() => uploadImage()} />  
        </div> : <>
        <h3>Compartilhe com a Dux</h3>
        <div className='form__uploader' style={{ marginBottom: 0 }} {...getRootProps()}>
          <UploadCloud />  
          <h3>Arraste e solte seu arquivo</h3>
          <input type='file' id='uploader' name='uploader' {...getInputProps()} hidden/>
          <div className="form__btn">
            <Button style={{ marginTop: '12px' }} type={1} onClick={() => uploadImage()} scrollLink={true}>Explorar arquivos</Button>
          </div>
        </div></> : null}

        {chosenMission?.authorization ? <>
        <div className="mission__checkbox">
          <input type="checkbox" id="accept" name="accept" value="Bike"/>
          <label for="accept"><strong>Autorizo o uso no Discord da DUX</strong></label>
        </div>        
        <div className="mission__checkbox">
          <input type="checkbox" id="reject" name="reject" value="Bike"/>
          <label for="reject"><strong>Não autorizo o uso no Discord da DUX</strong></label>
        </div></> : null}      

        {chosenMission?.customFields ? chosenMission.customFields : null}
      </div>

      <div className="mission__terms">
        <p>Ao manifestar sua aceitação para com o presente termo, o titular dos dados aqui fornecidos, autoriza a empresa coletora DUX DIGITAL S.A, a realizar a coleta e armazenamento internacional de tais dados, bem como a sua manutenção em registros cadastrais de uso interno, durante o período de 5 anos.</p>
        <p>O tratamento dos dados pessoais coletados a partir do preenchimento deste formulário tem como finalidade a realização de cadastro interno na base de dados das empresas coletoras, sendo expressamente autorizada a utilização dos mesmos para a realização de contato comercial ligadas ao objeto social de tais empresas. As coletoras exercerão a função de controladora dos dados, e quaisquer compartilhamentos com outras empresas só serão feitos de forma acessória, de modo a viabilizar a comunicação e o envio de material, não sendo transferida a controladoria.</p>
        <p>As empresas coletoras informam que serão adotadas todas as medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de tratamentos inadequados ou ilícitos.</p>
        <p>Este consentimento poderá ser revogado pelo titular, a qualquer momento, mediante solicitação via <a target='_blank' rel='noreferrer' href='http://ouvidoria@duxcripto.com/'>e-mail</a>.</p>
        <div className="mission__checkbox">
          <input type="checkbox" id="accept-terms" name="accept-terms" value={acceptedTerms}/>
          <label for="accept-terms"><strong>Autorizo o tratamento e armazenamento dos dados, em conformidade com o disposto neste termo.</strong></label>
        </div>
      </div>

      <Button type={2} style={{ width: '30%', marginBottom: '32px' }}>Enviar</Button>
    </div>
  )
}

export default Mission