import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { X } from 'react-feather'
import axios from 'axios'

import './index.scss'
import { notifyError } from '../../helpers/errors'

const SOCIAL_TEXT = {
  google: { connect: "Conectar ao Google", connected: "Conectado ao Google", award: "Parabéns você ganhou 10 tokens $DUX" },
  twitter: { connect: "Conectar ao Twitter", connected: "Conectado ao Twitter", award: "Parabéns você ganhou 10 tokens $DUX" },
  instagram: { connect: "Conectar ao Instagram", connected: "Conectado ao Instagram", award: "Parabéns você ganhou 10 tokens $DUX" },
  facebook: { connect: "Conectar ao Facebook", connected: "Conectado ao Facebook", award: "Parabéns você ganhou 10 tokens $DUX" },
  tiktok: { connect: "Conectar ao TikTok", connected: "Conectado ao TikTok", award: "Parabéns você ganhou 10 tokens $DUX" },
  discord: { connect: "Conectar ao Discord", connected: "Conectado ao Discord", award: "Parabéns você ganhou 10 tokens $DUX" },
}

const SOCIAL_ICONS = {
  google: <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.0733 1.0289C4.14805 1.68765 2.50755 3.1284 1.5938 4.95165C1.27505 5.58065 1.04555 6.2479 0.905301 6.94065C0.556801 8.65765 0.799051 10.4894 1.58955 12.0534C2.1038 13.0734 2.8433 13.9829 3.7358 14.6926C4.58155 15.3641 5.5633 15.8656 6.6088 16.1419C7.9263 16.4946 9.3288 16.4861 10.6548 16.1844C11.8533 15.9081 12.9881 15.3344 13.8933 14.5014C14.8496 13.6216 15.5338 12.4656 15.8951 11.2204C16.2903 9.86465 16.3413 8.4154 16.0948 7.0214C13.6171 7.0214 11.1351 7.0214 8.6573 7.0214C8.6573 8.0499 8.6573 9.0784 8.6573 10.1069C10.0938 10.1069 11.5303 10.1069 12.9668 10.1069C12.8011 11.0929 12.2146 11.9939 11.3858 12.5506C10.8631 12.9034 10.2638 13.1286 9.6433 13.2391C9.0228 13.3454 8.3768 13.3581 7.7563 13.2349C7.12305 13.1074 6.5238 12.8439 5.9968 12.4741C5.1553 11.8834 4.51355 11.0206 4.1863 10.0474C3.85055 9.05715 3.8463 7.9564 4.1863 6.9704C4.4243 6.2734 4.8153 5.63165 5.3338 5.10465C5.9713 4.45015 6.80005 3.98265 7.6968 3.7914C8.4618 3.6299 9.2693 3.65965 10.0173 3.8849C10.6548 4.07615 11.2413 4.4289 11.7215 4.8879C12.206 4.4034 12.6906 3.9189 13.1751 3.4344C13.4301 3.17515 13.6978 2.9244 13.9443 2.65665C13.2091 1.97665 12.3421 1.4284 11.3986 1.0799C9.69855 0.450898 7.78605 0.438148 6.0733 1.0289Z" fill="white" />
    <path d="M6.07325 1.02841C7.78175 0.437664 9.6985 0.450414 11.3985 1.07516C12.342 1.42366 13.2048 1.96766 13.9443 2.65191C13.6978 2.91966 13.43 3.17041 13.175 3.42966C12.6905 3.91416 12.206 4.39866 11.7215 4.88316C11.2413 4.42416 10.6548 4.07566 10.0173 3.88016C9.26925 3.65491 8.46175 3.62091 7.69675 3.78666C6.80425 3.97791 5.9755 4.44541 5.33375 5.09991C4.81525 5.62266 4.42425 6.26866 4.18625 6.96566C3.3235 6.29416 2.46075 5.62691 1.59375 4.95541C2.5075 3.12791 4.148 1.68716 6.07325 1.02841Z" fill="#EA4335" />
    <path d="M0.909471 6.93627C1.04972 6.24777 1.27922 5.57627 1.59797 4.94727C2.46072 5.61877 3.32347 6.28602 4.19047 6.95752C3.85047 7.94777 3.85047 9.04852 4.19047 10.0345C3.32772 10.706 2.46497 11.3775 1.60222 12.0448C0.803221 10.485 0.560971 8.65327 0.909471 6.93627Z" fill="#FBBC05" />
    <path d="M8.65733 7.0166C11.1351 7.0166 13.6171 7.0166 16.0948 7.0166C16.3413 8.40635 16.2861 9.8556 15.8951 11.2156C15.5338 12.4609 14.8496 13.6169 13.8933 14.4966C13.0561 13.8464 12.2188 13.1961 11.3816 12.5459C12.2103 11.9891 12.7968 11.0881 12.9626 10.1021C11.5261 10.1021 10.0896 10.1021 8.65308 10.1021C8.65733 9.0736 8.65733 8.0451 8.65733 7.0166Z" fill="#4285F4" />
    <path d="M1.59375 12.0483C2.4565 11.3811 3.31925 10.7096 4.182 10.0381C4.5135 11.0113 5.151 11.8741 5.9925 12.4648C6.5195 12.8346 7.123 13.0981 7.752 13.2256C8.3725 13.3531 9.01425 13.3361 9.639 13.2298C10.2595 13.1193 10.8587 12.8941 11.3815 12.5413C12.2188 13.1916 13.056 13.8418 13.8932 14.4921C12.988 15.3293 11.8532 15.8988 10.6547 16.1751C9.32875 16.4768 7.92625 16.4853 6.60875 16.1326C5.56325 15.8563 4.5815 15.3591 3.73575 14.6833C2.8475 13.9778 2.108 13.0683 1.59375 12.0483Z" fill="#34A853" />
  </svg>,
  twitter: <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.97513 16.2197C13.145 16.2197 17.0666 10.2796 17.0666 5.12828C17.0666 4.95958 17.0666 4.79162 17.0552 4.62441C17.8181 4.07256 18.4766 3.38933 19 2.60662C18.2886 2.92187 17.5338 3.12857 16.761 3.21993C17.5746 2.73291 18.1839 1.9663 18.4748 1.0638C17.7097 1.51783 16.8724 1.83787 15.9995 2.01002C15.2625 1.22623 14.233 0.78125 13.1572 0.78125C11.0167 0.78125 9.25534 2.54261 9.25534 4.68308C9.25534 4.98003 9.28926 5.27605 9.35635 5.56528C6.22365 5.40827 3.30084 3.92649 1.32239 1.49245C0.293572 3.26353 0.825906 5.55875 2.52926 6.69615C1.90898 6.67778 1.30202 6.51042 0.76 6.20824V6.25763C0.76052 8.1062 2.07542 9.71292 3.88739 10.0789C3.31353 10.2354 2.71129 10.2583 2.12726 10.1458C2.63666 11.7298 4.1056 12.8217 5.76917 12.8529C4.38907 13.9375 2.68327 14.5267 0.927957 14.5249C0.617797 14.5243 0.307934 14.5056 0 14.4687C1.78251 15.6126 3.85715 16.2195 5.97513 16.2167" fill="#1DA1F2" />
  </svg>,
  instagram: <svg width="19" height="17" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <radialGradient cx="56.35" cy="19.218" gradientTransform="matrix(0.9986 -5.233596e-02 4.448556e-02 0.8488 -36.9742 443.8014)" gradientUnits="userSpaceOnUse" id="A" r="711.335">
      <stop offset="0" stopColor="#fed576" />
      <stop offset=".263" stopColor="#f47133" />
      <stop offset=".609" stopColor="#bc3081" />
      <stop offset="1" stopColor="#4c63d2" />
    </radialGradient>
    <path d="M96.1 23.2c-16.2 6.3-29.9 14.7-43.6 28.4C38.8 65.2 30.4 79 24.1 95.1c-6.1 15.6-10.2 33.5-11.4 59.7s-1.5 34.6-1.5 101.4l1.5 101.4c1.2 26.2 5.4 44.1 11.4 59.7 6.3 16.2 14.7 29.9 28.4 43.6s27.4 22.1 43.6 28.4c15.6 6.1 33.5 10.2 59.7 11.4s34.6 1.5 101.4 1.5 75.2-.3 101.4-1.5 44.1-5.4 59.7-11.4c16.2-6.3 29.9-14.7 43.6-28.4s22.1-27.4 28.4-43.6c6.1-15.6 10.2-33.5 11.4-59.7s1.5-34.6 1.5-101.4-.3-75.2-1.5-101.4-5.4-44.1-11.4-59.7C484 79 475.6 65.2 462 51.6c-13.7-13.7-27.4-22.1-43.6-28.4-15.6-6.1-33.5-10.2-59.7-11.4s-34.6-1.5-101.4-1.5-75.2.3-101.4 1.5c-26.3 1.1-44.2 5.3-59.8 11.4zM356.6 56c24 1.1 37 5.1 45.7 8.5 11.5 4.5 19.7 9.8 28.3 18.4s13.9 16.8 18.4 28.3c3.4 8.7 7.4 21.7 8.5 45.7 1.2 25.9 1.4 33.7 1.4 99.4l-1.4 99.4c-1.1 24-5.1 37-8.5 45.7-4.5 11.5-9.8 19.7-18.4 28.3s-16.8 13.9-28.3 18.4c-8.7 3.4-21.7 7.4-45.7 8.5-25.9 1.2-33.7 1.4-99.4 1.4s-73.5-.3-99.4-1.4c-24-1.1-37-5.1-45.7-8.5-11.5-4.5-19.7-9.8-28.3-18.4s-13.9-16.8-18.4-28.3c-3.4-8.7-7.4-21.7-8.5-45.7-1.2-25.9-1.4-33.7-1.4-99.4l1.4-99.4c1.1-24 5.1-37 8.5-45.7 4.5-11.5 9.8-19.7 18.4-28.3s16.8-13.9 28.3-18.4c8.7-3.4 21.7-7.4 45.7-8.5 25.9-1.2 33.7-1.4 99.4-1.4l99.4 1.4z" fill="url(#A)" /><radialGradient cx="154.073" cy="134.55" gradientTransform="matrix(0.9986 -5.233596e-02 4.448556e-02 0.8488 -24.3617 253.2946)" gradientUnits="userSpaceOnUse" id="B" r="365.28">
      <stop offset="0" stopColor="#fed576" />
      <stop offset=".263" stopColor="#f47133" />
      <stop offset=".609" stopColor="#bc3081" />
      <stop offset="1" stopColor="#4c63d2" />
    </radialGradient>
    <path d="M130.9 256.3c0 69.8 56.6 126.3 126.3 126.3S383.5 326 383.5 256.3 327 130 257.2 130s-126.3 56.5-126.3 126.3zm208.3 0c0 45.3-36.7 82-82 82s-82-36.7-82-82 36.7-82 82-82 82 36.7 82 82z" fill="url(#B)" />
    <circle cx="388.6" cy="125" r="29.5" fill="#654c9f" />
  </svg>,
  facebook: <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 8.5C17 3.80558 13.1944 0 8.5 0C3.80558 0 0 3.80558 0 8.5C0 12.7426 3.10834 16.2591 7.17188 16.8967V10.957H5.01367V8.5H7.17188V6.62734C7.17188 4.49703 8.44087 3.32031 10.3825 3.32031C11.3124 3.32031 12.2852 3.48633 12.2852 3.48633V5.57812H11.2133C10.1574 5.57812 9.82812 6.23332 9.82812 6.90552V8.5H12.1855L11.8087 10.957H9.82812V16.8967C13.8917 16.2591 17 12.7426 17 8.5Z" fill="#1877F2" />
    <path d="M11.8087 10.957L12.1855 8.5H9.82812V6.90552C9.82812 6.23332 10.1574 5.57812 11.2133 5.57812H12.2852V3.48633C12.2852 3.48633 11.3124 3.32031 10.3825 3.32031C8.44087 3.32031 7.17188 4.49703 7.17188 6.62734V8.5H5.01367V10.957H7.17188V16.8967C7.60461 16.9646 8.04817 17 8.5 17C8.95183 17 9.39536 16.9646 9.82812 16.8967V10.957H11.8087Z" fill="white" />
  </svg>,
  tiktok: <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.2187 5.75996V8.5209C17.0702 8.5209 16.8921 8.55059 16.714 8.55059C15.378 8.55059 14.1312 8.0459 13.1812 7.24434V13.1225C13.1812 14.31 12.7952 15.4381 12.1124 16.3287C11.1624 17.6053 9.61865 18.4662 7.86709 18.4662C5.96709 18.4662 4.30459 17.4568 3.38428 15.9725C4.33428 16.8631 5.61084 17.4271 7.00615 17.4271C8.72803 17.4271 10.2718 16.5959 11.2218 15.2896C11.8749 14.399 12.2905 13.3006 12.2905 12.0834V6.17559C13.2405 7.00684 14.4577 7.48184 15.8233 7.48184C16.0015 7.48184 16.1499 7.48184 16.328 7.45215V5.6709C16.5952 5.73027 16.8327 5.75996 17.0999 5.75996H17.2187Z" fill="#FF004F" />
    <path d="M8.60928 7.80801V10.8658C8.40146 10.8064 8.16396 10.7768 7.95615 10.7768C6.6499 10.7768 5.58115 11.8752 5.58115 13.2111C5.58115 13.508 5.64053 13.7752 5.72959 14.0424C5.13584 13.5971 4.72021 12.8846 4.72021 12.083C4.72021 10.7471 5.78896 9.64863 7.09521 9.64863C7.33271 9.64863 7.54053 9.67832 7.74834 9.73769V7.77832C7.80772 7.77832 7.86709 7.77832 7.92647 7.77832C8.16397 7.77832 8.40146 7.77832 8.60928 7.80801Z" fill="#FF004F" />
    <path d="M13.6264 3.56289C13.092 3.08789 12.7061 2.43477 12.4983 1.75195H13.2108C13.2108 1.90039 13.2108 2.01914 13.2108 2.16758C13.2702 2.64258 13.4186 3.11758 13.6264 3.56289Z" fill="#FF004F" />
    <path d="M16.3579 5.70039V7.48164C16.2094 7.51133 16.0313 7.51133 15.8532 7.51133C14.5172 7.51133 13.2704 7.00664 12.3204 6.20508V12.0832C12.3204 13.2707 11.9344 14.3988 11.2516 15.2895C10.2719 16.5957 8.75786 17.427 7.03599 17.427C5.64067 17.427 4.36411 16.8629 3.41411 15.9723C2.90942 15.141 2.61255 14.191 2.61255 13.152C2.61255 10.2723 4.89849 7.92695 7.74849 7.83789V9.79727C7.54067 9.73789 7.30317 9.7082 7.09536 9.7082C5.78911 9.7082 4.72036 10.8066 4.72036 12.1426C4.72036 12.9441 5.1063 13.6863 5.72974 14.102C6.0563 15.0223 6.94692 15.7051 7.9563 15.7051C9.26255 15.7051 10.3313 14.6066 10.3313 13.2707V1.75195H12.4985C12.7063 2.46445 13.0922 3.08789 13.6266 3.56289C14.161 4.63164 15.1704 5.4332 16.3579 5.70039Z" fill="black" />
    <path d="M7.74844 6.76836V7.77773C4.89844 7.8668 2.6125 10.2121 2.6125 13.0918C2.6125 14.1309 2.90937 15.0809 3.41406 15.9121C2.40469 14.9324 1.78125 13.5668 1.78125 12.023C1.78125 9.08398 4.15625 6.70898 7.06562 6.70898C7.30312 6.70898 7.54063 6.73867 7.74844 6.76836Z" fill="#00F7EF" />
    <path d="M12.4985 1.75156H10.3313V13.2109C10.3313 14.5469 9.26255 15.6453 7.9563 15.6453C6.91724 15.6453 6.0563 14.9922 5.72974 14.0422C6.11567 14.3094 6.59067 14.4875 7.09536 14.4875C8.40161 14.4875 9.47036 13.4187 9.47036 12.0828V0.59375H12.35V0.653125C12.35 0.771875 12.35 0.890625 12.3797 1.00938C12.3797 1.24688 12.4391 1.51406 12.4985 1.75156Z" fill="#00F7EF" />
    <path d="M16.3579 4.60156V5.67031C15.1704 5.43281 14.161 4.63125 13.5969 3.5625C14.3391 4.24531 15.2891 4.63125 16.3579 4.60156Z" fill="#00F7EF" />
  </svg>,
  discord: <svg width="18px" height="18px" viewBox="0 -28.5 256 256" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><g><path d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z" fill="#5865F2" fill-rule="nonzero"></path></g></svg>
}

export default function DataVault() {
  const dispatch = useDispatch()
  const { app, dataVault: { showModalDataVault, connected } } = useSelector(state => state)
  const userId = app?.user?.id
  const baseUrl = axios.defaults.baseURL.replace("127.0.0.1", "localhost")

  useEffect(() => {
    if (showModalDataVault) {
      document.body.style.overflowY = 'hidden'
      fetchSocialAuthConfirmation()
    }
    return () => document.body.style.overflowY = 'auto'
  }, [showModalDataVault])

  const handleClickClose = () => { dispatch({ type: "CHANGE_DATA_VAULT_MODAL", payload: false }) }

  const openPopup = (path, title) => {
    const width = 450, height = 730
    const left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2
    const popup = window.open(path, title, `menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=${width},height=${height},top=${top},left=${left}`)
    const timer = setInterval(() => {
      if (popup.closed) {
        clearInterval(timer)
        fetchSocialAuthConfirmation()
      }
    }, 1000);
  }

  const fetchSocialAuthConfirmation = () => {
    if (userId) {
      axios.get(`/user/social/auth/login/${userId}`)
        .then(response => {
          const provider = Object.keys(response.data)
          provider.forEach(key => {
            dispatch({ type: "CHANGE_DATA_VAULT_CONNECTED", payload: { key, value: response.data[key] } })
          })
        })
        .catch(err => {
          console.error(err)
          notifyError(err.response)
        })
    }
  }

  const SocialButton = ({ connected = false, keyName = "", disabled }) => {
    const connectText = SOCIAL_TEXT[keyName].connect,
      connectedText = SOCIAL_TEXT[keyName].connected,
      awardText = SOCIAL_TEXT[keyName].award

    return (
      <div
        className={
          disabled
            ? "data-vault__modal__buttons__button data-vault__modal__buttons__button--disabled"
            : "data-vault__modal__buttons__button"
        }
        onClick={() => !connected && openPopup(`${baseUrl}/user/social/auth/${keyName}/${userId}`, keyName)}
        style={{ cursor: connected ? 'default' : 'pointer' }}
      >
        {!connected && SOCIAL_ICONS[keyName]}
        <section>
          <div>{!connected ? connectText : connectedText}</div>
          {connected && <small>{awardText}</small>}
        </section>
      </div>
    )
  }

  return (
    <section className={showModalDataVault ? "data-vault data-vault--show" : "data-vault"}>
      <div className="data-vault__modal">
        <header>
          <div className="data-vault__modal__title">
            <h3>Data Vault</h3>
            <span>Disponível em breve.</span>
          </div>
          <X onClick={handleClickClose} />
        </header>
        <main>
          <span>
            O seus dados são ativos e merecem ser recompensados. Conecte suas redes e ganhe tokens $WDUX
          </span>
          <div className="data-vault__modal__buttons">
            <SocialButton connected={connected.discord} keyName="discord" />
            <SocialButton connected={connected.instagram} keyName="instagram" disabled />
            <SocialButton connected={connected.facebook} keyName="facebook" disabled />
            <SocialButton connected={connected.twitter} keyName="twitter" disabled />
            <SocialButton connected={connected.google} keyName="google" disabled />
            <SocialButton connected={connected.tiktok} keyName="tiktok" disabled />
          </div>
        </main>
      </div>
    </section>
  )
}
