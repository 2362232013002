import React from "react";

import './index.scss'

const Button = ({ type, children, onClick, style = {} }) => {
  return (
    <div className={`button button--type-${type}`}
      onClick={onClick} style={style}>
      {children}
    </div>
  )
}

export default Button
