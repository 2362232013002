import { toast } from 'react-toastify'

export const translateError = (message) => {
  switch (message) {
    case 'Address not authorized to use platform, is not a holder.':
      return 'Address is not a holder'
    case 'Nome é obrigatório':
      return 'Username is mandatory'
    case 'Email inválido':
      return 'Email inválido'
    case 'Estado é obrigatório':
      return 'Estado é obrigatório'
    case 'Cidade é obrigatório':
      return 'Cidade é obrigatório'
    case 'Install Metamask':
      return 'Install Metamask on your browser'
    case 'username or pinCode incorrect!':
      return 'Incorrect username or pin code'
    case 'username does not exist!':
      return 'Incorrect username or pin code'
    case 'Wallet address not found in database.':
      return 'Incorrect username or pin code'
    case 'User does not have any wallet yet':
      return 'User does not have any wallet yet'
    case 'User not found':
      return 'User not found'
    case 'This card is already on your list':
      return 'Este card já está na sua lista'
    case 'This address does not belong to your account':
      return 'This address does not belong to your account'
    case 'Token is missing':
      return 'Token is missing'
    case 'Token is invalid':
      return 'Token is invalid'
    case "Email already registered":
      return 'Email já cadastrado'
    case "Username already registered":
      return 'Username already registered'
    case "passwords don't match":
      return `Passwords don't match`
    case "All fields must be filleds":
      return 'All fields must be filleds'
    case "password must have 6 characteres":
      return 'Password must have 6 characteres'
    case "Arquivo inválido":
      return 'Formato de Arquivo inválido. Formatos aceitos: .jpg, .jpeg e .png'
    case "Arquivo de imagem inválido":
      return 'Formato de arquivo inválido.'
    case "Terms agreement":
      return 'Terms agreement is mandatory'
    case "Not enough balance":
      return 'Not enough balance'
    default:
      // return message
      return 'Unexpected error. Try again.'
      break
  }
}

export const notifyError = (message) => {
  toast.error(translateError(message))
}