import { useState } from 'react'
import { useSelector } from 'react-redux'
import { X, Check } from 'react-feather'
import './index.scss'

import Header from '../../components/Header'
import Input from '../../components/Input'
import Button from '../../components/Button'
import profileImage from '../../assets/profileImage.png'
import metamask from '../../assets/icons/metamask.png'

function UserProfile() {
  const [username, setUsername] = useState('')
  // const [description, setDescription] = useState('')
  const [phone, setPhone] = useState('')
  const [document, setDocument] = useState('')
  const { user } = useSelector(state => state.app)
  
  return (
    <div className='user-profile'>
      <Header title={'Meu perfil'} greeting={false} subTitle='Complete informações e alcance novos níveis no seu perfil.' />
      <div className='user-profile__header'>
        <div className='user-profile__stats'>
          <img src={profileImage} alt='profile pic' />
          <div className="user-profile__address">
            {/* {user.email} */}
            <img src={metamask} alt='metamask' style={{ height: '24px', width: '24px' }} />
            <p>{user.address}</p>
          </div>
        </div>

        <div className="user-profile__levels">
          <div className="user-profile__level">
            <div className="user-profile__highlight">Preencha e avance!</div>
            <h4>Nível 1 de perfil</h4>
            <ul>
              <li>{user.email ? <Check size={14}/> : <X size={14}/>}E-mail</li>
              <li>{user.address ? <Check size={14}/> : <X size={14}/>}Wallet</li>
            </ul>
          </div>
          <div className="user-profile__level">
            <h4>Nível 2 de perfil</h4>
            <ul>
              <li>{user.avatar ? <Check size={14}/> : <X size={14}/>}Foto de perfil</li>
              <li>{user.username ? <Check size={14}/> : <X size={14}/>}Username</li>
              <li>{user.description ? <Check size={14}/> : <X size={14}/>}Descrição</li>
            </ul>
          </div>
          <div className="user-profile__level">
            <h4>Nível 3 de perfil</h4>
            <ul>
              <li>{user.phone_number ? <Check size={14}/> : <X size={14}/>}Celular</li>
              <li>{user.cpf ? <Check size={14}/> : <X size={14}/>}CPF</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="user-profile__edit">
        <div className="user-profile__password">
          <h2>Redefinir senha</h2>
          {/* <h3>Senha atual</h3>
          <Input type={2} password={true} /> */}
          <h3>Nova senha</h3>
          <Input type={2} password={true} />
          <h3>Repetir nova senha</h3>
          <Input type={2} password={true} />
          <Button style={{ width: '50%' }}type={1}>Redefinir senha</Button>
        </div>

        <div className="user-profile__edit-line"/>

        <div className="user-profile__additional-info">
          <h2>Complete seu perfil</h2>
          <h3>Username/Nickname</h3>
          <Input onChange={setUsername} value={username} placeholder="Nome de usuário" type={2} />
          {/* <h3>Descrição</h3>
          <textarea placeholder='Escreva aqui...' onChange={e => setDescription(e.target.value)} value={description}></textarea> */}
          <h3>Celular</h3>
          <Input onChange={setPhone} value={phone} placeholder="Seu celular"type={2} />
          <h3>CPF</h3>
          <Input onChange={setDocument} value={document} placeholder="Seu documento"type={2} />
          <Button style={{ width: '50%' }}type={1}>Salvar informações</Button>
        </div>
      </div>
    </div>
  )
}

export default UserProfile