import React from 'react'
import { useLocation } from 'react-router-dom'
import './index.scss'

import terms from '../../assets/terms.pdf'
import privacy from '../../assets/privacy.pdf'
// import Header from '../../components/Header'

const Document = () => {
  const location = useLocation()
  return (
    <div className='document'>
      {/* <Header document /> */}
      <iframe title="embed document" src={location.pathname.includes('terms') ? terms  + "#toolbar=0" : privacy + "#toolbar=0"} />
    </div>
  )
}

export default Document