import { useEffect, useState } from 'react'
import './index.scss'

import Header from '../../components/Header'
import OrganizationCard from '../../components/OrganizationCard'
import { getAllOrganizations } from '../../api'

const Organizations = () => {
  const [organizations, setOrganizations] = useState([])

  useEffect(() => {
    getAllOrganizations().then(response => {
      console.log(response)
      setOrganizations(response.data)
    })
  }, [])

  return (
    <div className='organizations'>
      <Header title={'Organizações'} subTitle={'Agora você pode realizar missões por organização. Clique abaixo e navegue pelas organizações parceiras DUX'} />
      <div className="organizations__cards">
        {organizations.map(organization => {
          return (
            <OrganizationCard name={organization.name} imageUrl={organization.image_url} />
          )})}
      </div>
    </div>
  )
}

export default Organizations