import React, { useState } from "react";
import { PulseLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { sha3_256 } from 'js-sha3';
import { notifyError } from "../../helpers/errors";
import axios from "axios";
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import './index.scss'

import Button from '../../components/Button'
import metamask from '../../assets/icons/metamask.png'

const LoginModal = ({ visitor }) => {
  let history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  async function login() {
    setLoading(true)
    const { ethereum } = window
    let address

    try {
      if (window.ethereum) {
        address = await ethereum.request({
          method: 'eth_requestAccounts',
        })
      } else {
        notifyError('Install Metamask')
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }

    try {
      if (address.length > 0) {
        const nonce = parseInt(await ethereum.request({
          method: 'eth_getTransactionCount',
          params: [address[0], "latest"]
        }), 16)
        const message = `A assinatura dessa mensagem tem por objetivo garantir um login seguro. Essa ação não causará nenhuma cobrança. \n\n Nonce: ${sha3_256(nonce.toString())}`

        const hash = await ethereum.request({ method: 'personal_sign', params: [message, address[0]] })
        let locale = 'pt'
        let chainId = 5

        axios.post('/user/login', { address: address[0], chainId, locale, hash })
          .then(response => {
            const { account, token } = response.data
            localStorage.setItem('user', JSON.stringify(account))
            localStorage.setItem('token', token)
            localStorage.setItem('visitor', JSON.stringify(false))
            axios.defaults.headers.common['authorization'] = `Bearer ${token}`
            dispatch({ type: 'CHANGE_VISITOR', payload: false })
            dispatch({ type: 'CHANGE_USER', payload: account })
            history.push('/welcome')
            setLoading(false)
          }).catch(e => {
            if (e.response.data.message === 'Wallet address not found in database.'){
              dispatch({ type: 'CHANGE_ADDRESS', payload: address[0] })
              dispatch({ type: 'CHANGE_NEW_USER', payload: true })
            }
            else {
              notifyError(e.response.data.message)
              setLoading(false)
            }
          })
      }
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <div className={visitor ? 'login-modal login-modal--visible' : 'login-modal'}>
      <div className="login-modal__text">
        Visitor mode
        <span>You are viewing as a visitor</span>
      </div>
      <div className="login-modal__button">
        <Button type={2} onClick={() => !loading && login()}>
          {loading ? <PulseLoader size={10} /> : <><img src={metamask} alt='metamask' />Sign in with Metamask</>}
        {/* <Button type={2} onClick={() => !loading && history.push('/login')} > */}
          {/* {loading ? <PulseLoader size={10} /> : <>Fazer login</>} */}
        </Button>
      </div>
    </div>
  )
}

export default LoginModal