import './index.scss'

const Card = ({ title, text, image, big, selected, onClick }) => {
  return (
    <div onClick={onClick} className={selected ? 'card card--selected' : 'card'}>
      {image}
      <h4 className={big ? 'card__title card__title--big' : 'card__title'}>{title}</h4>
      <p className={big ? 'card__text card__text--big' : 'card__text'}>{text}</p>
    </div>
  )
}

export default Card