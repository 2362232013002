import './index.scss'

import profileImage from '../../assets/profileImage.png'

const OrganizationCard = ({ name, imageUrl }) => {
  return (
    <div className='organization-card'>
      {imageUrl ?
        <img src={imageUrl} alt='organization pic' />
        : <img src={profileImage} alt='organization pic' />
      }
      {name}
    </div>
  )
}

export default OrganizationCard