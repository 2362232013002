import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Edit, Edit2, MessageSquare, ThumbsUp, Feather, Gift, Star, Target } from 'react-feather';

import Card from '../../../../components/Card'

function CreationStep() {
  const dispatch = useDispatch()
  const { dux } = useSelector(state => state.app)
  const [showMissionTypes, setShowMissionTypes] = useState(false)
  const mockText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis habitasse eu."
  
  function setStep(step) {
    dispatch({ type: 'CHANGE_CREATION_STEP', payload: step })
  }
  
  function goToForm(form) {
    setStep('Preencher informações')
    dispatch({ type: 'CHANGE_FORM_TYPE', payload: form })
  }

  function setFormSubType(form) {
    setStep('Preencher informações')
    dispatch({ type: 'CHANGE_FORM_SUBTYPE', payload: form })
  }

  return (
    <>
      <h1>Selecione o que deseja criar</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis habitasse eu.</p>
      <div className="form__cards">
        <Card big onClick={() => goToForm('jornada')} title={"Jornada"} text={mockText} image={<Feather />} />
        <Card selected={showMissionTypes} big onClick={() => setShowMissionTypes(true)} title={"Missão"} text={mockText} image={<Target />} />
        {dux && <Card big onClick={() => goToForm('benefício')} title={"Benefício"} text={mockText} image={<Gift />} />}
        {dux && <Card big onClick={() => goToForm('badge')} title={"Badge"} text={mockText} image={<Star />} />}
      </div>
      {showMissionTypes && 
      <>
        <h2 className='form__title-small'>Criar missão</h2>
        <div className="form__cards">
          <Card big onClick={() => setFormSubType('custom')} title={"Customizável"} text={mockText} image={<Edit />} />
          <Card big onClick={() => setFormSubType('engage')} title={"Engage to earn"} text={mockText} image={<ThumbsUp />} />
          <Card big onClick={() => setFormSubType('answer')} title={"Answer to earn"} text={mockText} image={<MessageSquare />} />
          <Card big onClick={() => setFormSubType('create')} title={"Create to earn"} text={mockText} image={<Edit2 />} />
        </div>
      </>}
    </>
  )
}

export default CreationStep