import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { Grid, Database, AlignJustify, ArrowLeft, Book, MessageCircle, Radio, ShoppingBag, CreditCard, User, Gift, DollarSign, FileText, X, Plus, Folder } from 'react-feather';
import axios from 'axios';
import './index.scss'

import duxLogoBlack from '../../assets/logos/duxLogoBlack.svg'
import { ReactComponent as Sandbox } from '../../assets/sandbox.svg'

function Menu() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { selectedContent } = useSelector(state => state.contents)
  const { page, menuVisible, isOrganization, visitor } = useSelector(state => state.app)

  useEffect(() => {
    let pathname = window.location.pathname
    pathname = pathname.replace('/', '')
    dispatch({ type: 'CHANGE_PAGE', payload: pathname })
  }, [])

  function handleClick(url, selectContent = false) {
    dispatch({ type: 'CHANGE_PAGE', payload: url })
    history.push(`/${url}`)

    if (selectContent) {
      dispatch({ type: 'CHANGE_SELECTED_CONTENT', payload: selectContent })
      closeMenu()
    }
  }

  function closeMenu() {
    dispatch({ type: 'CHANGE_MENU', payload: '' })
  }

  function handleLogout() {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('visitor')
    axios.defaults.headers.common['authorization'] = ''
    dispatch({ type: 'CHANGE_USER', payload: null })
    dispatch({ type: 'CHANGE_VISITOR', payload: false })
    // history.push('/login')
    window.location.reload();
  }  

  return (
    <div className={menuVisible === 'menu' ? 'menu menu--visible' : 'menu'}>
      <div className="menu__close">
        <X size={30} onClick={() => closeMenu()} />
      </div>
      <img className='menu__logo' src={duxLogoBlack} alt='dux logo' onClick={() => handleClick('welcome')} />
      <div className='menu__icons-container'>
        <div title={'Home'} className={(page === 'welcome') ? 'menu__icon menu__icon menu__icon--selected' : 'menu__icon'} onClick={() => handleClick('welcome')}>
          <Grid />
          <h6>
            {'Home'}
          </h6>
        </div>
        {isOrganization ? 
          <>
          <div title={'Criar'} className={(page === 'form') ? 'menu__icon menu__icon menu__icon--selected' : 'menu__icon'} onClick={() => handleClick('form', 'form')}>
            <Plus /> 
            <h6>Criar</h6>
          </div>        
          <div title={'Minhas missões'} className={(page !== 'welcome' && selectedContent === 'missions') ? 'menu__icon menu__icon menu__icon--selected' : 'menu__icon'} onClick={() => handleClick('missions', 'missions')}>
            <AlignJustify /> 
            <h6>Minhas missões</h6>
          </div>        
          <div title={'Pagamentos'} className={(page !== 'welcome' && selectedContent === 'money') ? 'menu__icon menu__icon menu__icon--selected' : 'menu__icon'} onClick={() => handleClick('payments', 'money')}>
            <Database />
            <h6>Pagamentos</h6>
          </div>        
          <div title={'Organizações'} className={(page !== 'welcome' && selectedContent === 'organizations') ? 'menu__icon menu__icon menu__icon--selected' : 'menu__icon'} onClick={() => handleClick('organizations', 'organizations')}>
            <Grid />
            <h6>Organizações</h6>
          </div>
          </> :
          <>
            <div title='Hodler Space' className={(page === 'holder') ? 'menu__icon menu__icon menu__icon--selected' : 'menu__icon'} onClick={() => handleClick('holder', 'holder')}>
              <Sandbox />
              <h6>Holder Space</h6>
            </div>
            {/* <div title={'Jornada'} className={(page !== 'welcome' && selectedContent === 'education' && !page.includes('airdrops')) ? 'menu__icon menu__icon menu__icon--selected' : 'menu__icon'} onClick={() => handleClick('contents', 'education')}> */}
            <div title='Journeys' className='menu__icon menu__icon menu__icon--unavailable'>
              <Grid />
              <h6>Journeys</h6>
            </div>
            {/* <div title={'Benefícios'} className={((page !== 'welcome' && selectedContent === 'benefits') || page.includes('airdrops')) ? 'menu__icon menu__icon menu__icon--selected' : 'menu__icon'} onClick={() => handleClick('contents', 'benefits')}> */}
            <div title='Benefits' className='menu__icon menu__icon menu__icon--unavailable'>
              <Grid />
              <h6>Benefits</h6>
            </div>
            {/* <div className={(page === 'myprofile') ? 'menu__icon menu__icon menu__icon--selected' : 'menu__icon'} onClick={() => handleClick('myprofile', 'myprofile')}> */}
            <div className='menu__icon menu__icon menu__icon--unavailable'>  
              <Grid />
              <h6>My profile</h6>
            </div>
            {visitor ? null :
            <div className='menu__icon menu__icon menu__icon' onClick={() => handleLogout()}>  
              <ArrowLeft />
              <h6>Log out</h6>
            </div>}
            {/* <div className='menu__icon menu__icon menu__icon--unavailable'>
              <Grid />
              <h6>Organizações</h6>
            </div>
            <div className='menu__icon menu__icon menu__icon--unavailable'>
              <Grid />
              <h6>Investimento</h6>
            </div> */}
          </>
        }

        {/* <div className={page === 'chat' ? 'menu__icon menu__icon menu__icon--selected' : 'menu__icon'} onClick={() => handleClick('chat')}>
          <MessageCircle />
        </div> */}
      </div>
    </div>
  )
}

export default Menu;