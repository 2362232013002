import { useState, useCallback, useEffect } from 'react';
import { notifyError } from '../../helpers/errors';
import { Gift, UploadCloud, Pocket } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import './index.scss'

import FormStep from './components/FormStep';
import CreationStep from './components/CreationStep';
import Custom from './components/Custom';
import EngageToEarn from './components/EngageToEarn';
import AnswerToEarn from './components/AnswerToEarn';
import CreateToEarn from './components/CreateToEarn';

import Review from './components/Review'
import Card from '../../components/Card'
import Badge from '../../components/Badge'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Steps from '../../components/Steps'
import MissionFlow from './components/MissionFlow'
import Modal from '../../components/Modal';
import { ReactComponent as Wallet } from '../../assets/icons/wallet.svg'

function Form() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { user, dux } = useSelector(state => state.app)
  const { step, formType, formSubType, categories, subCategories, levels, chosenBadges } = useSelector(state => state.form)
  const [name, setName] = useState('')
  const [subCategory, setSubCategory] = useState('')
  const [subCategoryName, setSubCategoryName] = useState('')
  const [image, setImage] = useState('')
  const [imageBlob, setImageBlob] = useState('')
  const [description, setDescription] = useState('')
  const [level, setLevel] = useState('')
  const [levelName, setLevelName] = useState('')
  const [xp, setXp] = useState('')
  const [token, setToken] = useState('')
  const [rewardXp, setRewardXp] = useState(false)
  const [rewardToken, setRewardToken] = useState(false)
  const [rewardBadges, setRewardBadges] = useState(false)
  const [type, setType] = useState('')
  const [missionFlow, setMissionFlow] = useState(false)
  const [creatingContent, setCreatingContent] = useState(false)
  const [creatingMission, setCreatingMission] = useState(false)
  const [title, setTitle] = useState('')
  const [inputList, setInputList] = useState([])
  let userToken = localStorage.getItem('token')

  const badges = [{ name: "Badge 1" }, { name: "Badge 2" }, { name: "Badge 3" },  { name: "Badge 4" },  { name: "Badge 5" }, { name: "Badge 6" }, { name: "Badge 7" }, { name: "Badge 8" },  { name: "Badge 9" },  { name: "Badge 10" }, { name: "Badge 11" }, { name: "Badge 12" }, { name: "Badge 13" },  { name: "Badge 14" }]
  const mockText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis habitasse eu."
  const stepNames = ["Tipo de criação", "Preencher informações", "Revisar criação", "Criação finalizada"]

  useEffect(() => {
    window.scrollTo(0, 0)
    axios.get('content-card/get-settings').then(response => {
      dispatch({ type: 'CHANGE_CATEGORIES', payload: response.data.category })
      dispatch({ type: 'CHANGE_SUBCATEGORIES', payload: response.data.subCategory })
      dispatch({ type: 'CHANGE_LEVELS', payload: response.data.levels })
    }).catch(e => {
      console.log(e)
    })
  }, [])

  function clearFields() {
    setName('')
    setSubCategory('')
    setSubCategoryName('')
    setImage('')
    setImageBlob('')
    setDescription('')
    setLevel('')
    setLevelName('')
    setXp('')
    setToken('')
    setRewardXp(false)
    setRewardToken(false)
    setRewardBadges(false)
    setType('')
  }  
  
  function toggleChosenBadges(badge) {
    const index = chosenBadges.indexOf(badge)
    if (chosenBadges.indexOf(badge) > -1) {
     chosenBadges.splice(index, 1)
     dispatch({ type: 'CHANGE_BADGES', payload: chosenBadges })
    } else {
      chosenBadges.push(badge)
      dispatch({ type: 'CHANGE_BADGES', payload: chosenBadges })
    }
  }

  function setStep(step) {
    dispatch({ type: 'CHANGE_CREATION_STEP', payload: step })
  }

  function setPaymentModal(boolean) {
    dispatch({ type: 'CHANGE_PAYMENT_MODAL', payload: boolean })
  }

  function addInputToForm() {
    setInputList(inputList.concat(
      <>
        <h3>Pergunta {inputList.length + 1}</h3>
        <Input 
          placeholder={`Título da pergunta ${inputList.length +1}`} 
          type={2} 
        />
        {/* <input 
          onChange={(e) => handleSetValue(e.target.value, key)}
          value={value[key] ? value[key] : ""}
          key={inputList.length} 
          placeholder={`Título da pergunta ${inputList.length +1}`} 
          type={2} 
        /> */}
      </>
    ));
  }

  function createBadge() {
    setCreatingContent(true)
    const formData = new FormData()
    formData.append('badge', image)
    formData.append('name', name)
    formData.append('description', description)

    delete axios.defaults.headers.common['authorization']
    axios.post('/badge/create-badge',  formData, { headers: { Authorization: `Bearer ${user.id}` } })
    .then(response => {
      axios.defaults.headers.common['authorization'] = `Bearer ${userToken}`
      setStep("Criação finalizada")
      setCreatingContent(false)
    }).catch(e => {
      console.log(e)
      notifyError(e.response.message)
      setCreatingContent(false)
    })
  }

  function createJourney() {
    setCreatingContent(true)    
    const formData = new FormData()
    formData.append('title', name)
    formData.append('level_id', level)
    formData.append('type', 'TEST')
    formData.append('contentJorney', image)
    formData.append('description', description)
    // const orgId = '586e7b88-7392-4e37-9ea0-f4b35de09d8d'

    //TO DO: handle message Journey already exsits, please select another name to your Journey
    axios.post(`/content-journey/admin/586e7b88-7392-4e37-9ea0-f4b35de09d8d/create`, formData)
    .then(response => {
      setStep("Criação finalizada")
      setCreatingContent(false)
    }).catch(e => {
      console.log(e)
      notifyError(e.response.message)
      setCreatingContent(false)
    })
  }

  function createBenefit() {
  }

  function createMission() {
    setCreatingContent(true)
    const formData = new FormData()
    formData.append('contentCard', image)
    formData.append('name', name)
    formData.append('description', description)
    formData.append('level_id', level)
    formData.append('sub_category_id', subCategory)
    formData.append('category_id', "6d690021-25f8-4c76-ba04-f1921e549188")
    formData.append('info', 10)

    delete axios.defaults.headers.common['authorization']
    axios.post('content-card/admin/create',  formData, { headers: { Authorization: `Bearer ${user.id}` } })
    .then(response => {
      axios.defaults.headers.common['authorization'] = `Bearer ${userToken}`
      setStep("Criação finalizada")
      setCreatingContent(false)
    }).catch(e => {
      console.log(e)
      notifyError(e.response.message)
      setCreatingContent(false)
    })
  }

  function returnToCreationStep() {
    clearFields()
    dispatch({ type: 'CHANGE_CREATION_STEP', payload: 'Tipo de criação' })
    setStep('Tipo de criação')
  }

  function returnToHome() {
    history.push('/welcome')
    dispatch({ type: 'CHANGE_CREATION_STEP', payload: 'Tipo de criação' })
    dispatch({ type: 'CHANGE_PAGE', payload: 'welcome' })
  }

  function reviewAction(formType) {
    switch (formType) {
      case 'missão':
        return createMission()
      case 'badge':
          return createBadge()
      case 'jornada':
        return createJourney()
      case 'benefício':
        return createBenefit()
        break
    }
  }

  const conclusionStep = () => {
    return (
      <div className="form__modal">
        <div className="form__modal__content">
          <h1>Criação finalizada</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis habitasse eu.</p>
          <div className="form__btns form__btns--modal">
            <Button type={5} onClick={() => returnToHome()}>Voltar para a home</Button>
            <Button type={2} onClick={() => returnToCreationStep()}>Criar novamente</Button>
          </div>
        </div>
      </div>
    )
  }

  const reviewStep = () => {
    return (
      <>
        <h1>Revisar criação</h1>
        <Modal small formStep isVisible={step === "Criação finalizada"} close={returnToHome}>
          { conclusionStep() }
        </Modal>
        <Review
          name={name}
          subcategory={subCategoryName}
          type={type}
          level={levelName}
          xp={xp}
          token={token}
          badges={chosenBadges}
          image={imageBlob} 
          description={description}
          loading={creatingContent}
          onClickLeftBtn={() => setStep("Preencher informações")}
          onClickRightBtn={creatingContent ? null : () => reviewAction(formType)}
          formType={formType} />
      </>
    )
  }
  return (
    <div className='form'>
      <div className="form__steps">
        <Steps steps={stepNames} currentStep={step}/>
      </div>
        {
          // (step === "Preencher informações" && !formSubType) ? formStep() : 
          (step === "Preencher informações" && !formSubType) ? <FormStep missionFlow={missionFlow} name={name} setName={setName} level={level} setLevel={setLevel} description={description} setDescription={e => setDescription(e.target.value)} /> : 
          (step === "Preencher informações" && formSubType === 'custom') ? <Custom backStep={() => setStep('Tipo de criação')} nextStep={() => setPaymentModal(true)} /> : 
          (step === "Preencher informações" && formSubType === 'engage') ? <EngageToEarn backStep={() => setStep('Tipo de criação')} nextStep={() => setPaymentModal(true)} /> : 
          (step === "Preencher informações" && formSubType === 'answer') ? <AnswerToEarn backStep={() => setStep('Tipo de criação')} nextStep={() => setPaymentModal(true)} inputs={inputList} onClick={() => addInputToForm()} /> : 
          (step === "Preencher informações" && formSubType === 'create') ? <CreateToEarn backStep={() => setStep('Tipo de criação')} nextStep={() => setPaymentModal(true)} setTitle={setTitle} title={title}/> : 
          (step === "Revisar criação" || step === "Criação finalizada") ? reviewStep() : <CreationStep />
        }
    </div>
  )
}

export default Form