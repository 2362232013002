import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import './index.scss'

import Header from '../../components/Header'
import Carousel from "../../components/Carousel";

const Payments = () => {
  let history = useHistory()
  const dispatch = useDispatch()

  const deposit = [{
    SubCategories: { name: 'Crypto' },
    name: 'Coming soon'
  },
  {
    SubCategories: { name: 'FIAT' },
    name: 'Coming soon'
  }]

  const withdraw = [{
    SubCategories: { name: 'Crypto' },
    name: 'Coming soon'
  },
  {
    SubCategories: { name: 'Gamecoin' },
    name: 'Coming soon'
  },
  {
    SubCategories: { name: 'Pix' },
    name: 'Coming soon'
  },
  {
    SubCategories: { name: 'FIAT' },
    name: 'Coming soon'
  }]

  const fix = [{
    SubCategories: { name: 'USD' },
    name: 'Coming soon'
  },
  {
    SubCategories: { name: 'BRL' },
    name: 'Coming soon'
  },
  {
    SubCategories: { name: 'Staking' },
    name: 'Coming soon'
  },
  {
    SubCategories: { name: 'Stock Rental' },
    name: 'Coming soon'
  }]


  return (
    <div className='payments'>
      <Header title={'Pagamentos'} subTitle={'Realize pagamentos de forma fácil via cripto, FIAT ou gamecoins e tenha acesso a serviços financeiros para rentabilizar seu patrimônio!'} />
      <div className='payments__container'>
        Depositar
        <Carousel data={deposit} />
      </div>
      <div className='payments__container'>
        Sacar
        <Carousel data={withdraw} />
      </div>
      <div className='payments__container'>
        Renda Fixa
        <Carousel data={fix} />
      </div>
    </div>
  )
}

export default Payments