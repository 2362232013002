import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { UploadCloud } from 'react-feather'

import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import { notifyError } from '../../../../helpers/errors'

function NewContent({ 
  content, 
  name,
  setName,
  level,
  setLevel,
  description,
  setDescription,
  backStep,
  nextStep,
}) {
  const { step, formType, formSubType, categories, subCategories, levels, chosenBadges } = useSelector(state => state.form)
  const [image, setImage] = useState('')
  const [imageBlob, setImageBlob] = useState('')
  const validType = [ "image/jpeg", 'image/png' ]
  
  function uploadImage() { document.getElementById("uploader").click() }

  const handleUpload = useCallback(async(validFile) => {
    if (validFile[0]) {
      const convertBlobToBase64 = async (blob) => {
        return await blobToBase64(blob);
      }
      
      const blobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

      setImageBlob(await convertBlobToBase64(validFile[0]))
      setImage(validFile[0])
    } else {
      notifyError("Arquivo inválido")
    }
  }, [])   

  const {getRootProps, getInputProps} = useDropzone({
    accept: validType,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: handleUpload
  })   

  return (
    <>
      <h1>Criar {content}</h1>
      <div className="form__container">
        <h3>Título {content === 'benefício' ? 'do' : 'da'} {content}</h3>
        <Input type={2} value={name} onChange={setName} />

        {content === 'badge' ? null : <>
          <h3>Level</h3>
          <select name="level" id="level" value={level} onChange={setLevel}>
            <option value="" selected disabled hidden>Selecione um level</option>
            {levels.map(e => {
              return (
                <option key={e.id} value={e.id}>{e.name}</option>
              )
            })}
          </select>
        </>}

        <h3>Imagem da {content === 'badge' ? 'badge' : 'capa'}</h3>
        {image ?
        <div className={`form__image form__image--${content}`} {...getRootProps()}>
          <input type='file' id='uploader' name='uploader' {...getInputProps()} hidden/>
          {content === 'badge' ?
          <img src={imageBlob} alt="mission card" onClick={() => uploadImage()} /> :  
          <div className={`review__mission review__mission--${content}`}>
            <div className='carousel__card' onClick={() => uploadImage()} style={{ backgroundImage: `url(${imageBlob})` }} />
          </div>}
        </div> 
        :
        <div className='form__uploader' {...getRootProps()}>
          <UploadCloud />  
          <h3>Arraste e solte seu arquivo</h3>
          <input type='file' id='uploader' name='uploader' {...getInputProps()} hidden/>
          <div className="form__btn">
            <Button type={1} onClick={() => uploadImage()} scrollLink={true}>Explorar arquivos</Button>
          </div>
        </div>}
        <h3>Descrição</h3>
        <textarea placeholder='Escreva aqui...' onChange={setDescription} value={description}></textarea>
        <div className="form__btns">
          <Button type={10} onClick={backStep}>
            Voltar
          </Button>
          <Button type={2} onClick={nextStep}>
            Próxima etapa
          </Button>
        </div>
      </div>
    </>
  )
}

export default NewContent