import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone'
import { UploadCloud } from 'react-feather'

import MissionFlow from '../MissionFlow'
import NewContent from '../NewContent'
import Button from '../../../../components/Button'
import Input from '../../../../components/Input'
import { notifyError } from '../../../../helpers/errors'

function FormStep({
  name,
  setName,
  description,
  setDescription,
  level,
  setLevel,
  levels,
  categories,
}) {
  const dispatch = useDispatch()
  const { step, formType } = useSelector(state => state.form)
  const [missionFlow, setMissionFlow] = useState(false)

  function setStep(step) {
    dispatch({ type: 'CHANGE_CREATION_STEP', payload: step })
  }

  const setMissionFlowPage = () => {
    setMissionFlow(true)
    dispatch({ type: 'CHANGE_FORM_TYPE', payload: '' })
  }

  const createNewMission = () => {
    setMissionFlow(false)
    dispatch({ type: 'CHANGE_FORM_TYPE', payload: 'missão' })
  }
  
  return (
    (missionFlow && step === "Preencher informações") ? 
    <>
      <MissionFlow onClick={() => createNewMission()} nextStep={() => setStep("Revisar criação")} /> 
    </>
    : 
    <>
      <NewContent 
        content={formType} 
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        levels={levels}
        backStep={() => setStep('Tipo de criação')}
        nextStep={formType === 'jornada' ? () => setMissionFlowPage() : () => setStep('Revisar criação')}
      />
    </>
  )
  
}

export default FormStep