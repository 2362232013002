import { useState } from 'react'
import { Eye, EyeOff } from 'react-feather'
import { onlyNumber } from '../../constants/validate'
import './index.scss'
import { useEffect } from 'react'

const Input = ({
  value,
  placeholder,
  onChange,
  inputType,
  type = 1,
  validate = false,
  format,
  number,
  style,
  maxLength,
  password = false,
  onBlur = false,
  onPressEnter,
  wrapType,
  inputMode
}) => {
  const [hasValue, setHasValue] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
  }, [])


  function handlePressEnter(evt) {
    if (evt.key === 'Enter') {
      onPressEnter && onPressEnter()
    }
  }

  function handleChange(value) {
    if (format) {
      if (number) {
        return format(onlyNumber(value))
      }
      else {
        return format(value)
      }
    }
    else {
      if (number) {
        return onlyNumber(value)
      }
      else {
        return value
      }
    }
  }

  return (
    <div className={`input__wrapper input__wrapper--type-${wrapType}`}>
      <span className={hasValue || value ? `input__placeholder input__placeholder--open--type-${type}` : `input__placeholder`}>{placeholder}</span>
      <input
        className={`input input--type-${type}`}
        value={value}
        style={style}
        onChange={e => onChange && onChange(handleChange(e.target.value))}
        onBlur={() => onBlur && onBlur()}
        type={password && !showPassword ? 'password' : inputType}
        disabled={type === '3' ? true : false}
        onKeyPress={handlePressEnter}
        onAnimationStart={e => e.animationName === "onAutoFillStart" ? setHasValue(true) : setHasValue(false)}
        maxLength={maxLength}
        autoComplete="off"
        inputMode={inputMode}
      />
      {password && <span className={`input__password-eye`} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <EyeOff /> : <Eye />}</span>}
    </div>
  )
}

export default Input
