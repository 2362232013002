import axios from 'axios'
import env from '../env'

let protocolURL, stakeAddress
if (env === 'production') {
  stakeAddress = "0x2B0F7DF87a0D0c705b28EE3E67cc2B9646F33870"
  protocolURL = "https://protocol.lumxstudios.com"
} else {
  stakeAddress = "0x514adcAE6f88d56E6c20CAb2ce118FDD28147643"
  protocolURL = "http://localhost:3333"
}

export function setAuthToken(token) {
  axios.defaults.headers.common['authorization'] = `Bearer ${token}`
}

// Staking
export const getStakeData = async () => await axios.get(`user/get-stake-data`)

export const getUserStaking = async (address) => await axios.post(`${protocolURL}/account/read-function`, {
  params: [ address ],
  contractAddress: stakeAddress,
  methodName: "stakeInfos" 
})

export const approveAccount = async (amount, address) => await axios.post(`${protocolURL}/account/approve`, {
  spender: "0xf8F153857b2ecd51e2fcD7e98bdec126E24570F9",
  amount,
  contractAddress: "0x5Aec6c004E53914497A18c62Dc62a98863cB694E",
  address
})

export const checkMinedTransaction = async (transactionHash) => await axios.post('/user/get-transaction-approved', {
  txhash: transactionHash,
  apiKeyToken : "YourApiKeyToken"
})

export const executeStaking = async (address, methodName, contractAddress, amount, duration) => await axios.post(`${protocolURL}/account/execute-function`, {
  address,
  methodName, // stakeToken
  contractAddress,
  params: [amount, duration],
})

export const convertCoinToBRL = async () => await axios.get(`user/token-value/DUXUSDT`)

// PIX
export const buyDuxCoin = async (address, clientId, value) => await axios.post('user/tokens/buy', {
  address: address,
  clientId: clientId,
  value: value
})

export const verifyPixPayment = async (mintId, userId) => await axios.get(`/user/tokens/verify/${mintId}/${userId}`)

// Organization

export const getAllOrganizations = async () => await axios.get('/user/get_organizations')

// branch fix-simple-errors // test: "5987ad80-04d9-462c-b23f-0ba4ce2da0db"
export const getAllOrganizationInformation = async (organizationId) => 
await axios.get(`/user/organizations/${organizationId}`)

export const createOrganization = async (username, email, password, name, address, website, phoneNumber, goal) =>
await axios.post('user/organization/create', { 
  username: username,
  email: email,
  password: password,
  name: name,
  address: address,
  website: website,
  phoneNumber: phoneNumber,
  goal: goal
})

// { organization : Binário da foto, foundationDate, sourceOfCapital, sourceOfIncome }
export const editOrganizationUser = async (organizationsId, formData) =>
await axios.post(`user/organizations/profile/${organizationsId}`, {
  formData
})

// { organization : Binário da foto, foundationDate, sourceOfCapital, sourceOfIncome }
export const editOrganizationLegalInformation = async (organizationsId, formData) =>
await axios.post(`user/organizations/legal/${organizationsId}`, {
  formData
})

// { associateDocument, associateName, associateRole, birthDate, jurisdiction, citzenship, homeAddress, }
export const editOrganizationAssociate = async (organizationsId, formData) =>
await axios.post(`user/organizations/associates/${organizationsId}`, {
  formData
})


// Journey

// branch feature/create-route-get-all-journeys
export const getAllJourneys = async () => await axios.get('/content-journey/get-all')

// branch feature/create-route-get-all-journeys
export const getUserJourneys = async (userId) => 
await axios.post('/content-journey/get-journeys-user', { id: userId })

export const publishJourney = async (journeyId) => 
await axios.post(`/admin/${journeyId}/publish`) 

// branch create-route-get-all-journeys // test: "5987ad80-04d9-462c-b23f-0ba4ce2da0db"
export const getOrganizationJourneys = async (organizationId) => 
await axios.post('/content-journey/get-org-journeys', { org_id: organizationId })


// Mission

// branch feature/journey-contentcard-relation // test: "journey_id" : "6cd4cfaa-0ce9-4775-88a0-d298f25161de", "content_card_id" : "56a7008b-dd29-468c-bd0b-3040b998e08b"
export const boundMissionToOrganization = async (journeyId, missionId) => 
await axios.post(`/content-journey/include-content-card`, { 
  journey_id: journeyId,
  content_card_id: missionId
})

// export const boundMissionToJourney = async (journeyId) => 
// await axios.post(`/add-content-cards/${journeyId}`)

export const reviewMission = async (journeyId) => 
await axios.post(`/organization/${journeyId}/review`)

export const finishMission = async (journeyId, missionId, userId) => 
await axios.post(`/admin/${journeyId}/finish/${missionId}/${userId}`)

export const registerMission = async (journeyId) => 
await axios.post(`/register/${journeyId}`)

