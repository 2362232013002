import { useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useDraggable } from "react-use-draggable-scroll"
import { Edit2, X } from 'react-feather';
import axios from 'axios';
import { createAlchemyWeb3 } from "@alch/alchemy-web3"
import { ABIDux } from '../../assets/contracts/dux';
import { formatBRLNumber } from 'helpers'
import './index.scss'

import duxYellowLogo from '../../assets/logos/duxLogoYellow.svg'
import metamask from '../../assets/icons/metamask.png'
import lumxLogo from '../../assets/logos/lumxLogo.svg'
import duxWhiteLogo from '../../assets/logos/duxLogoWhite.svg'
import profileImage from '../../assets/profileImage.png'
import DataVaultModal from '../../components/DataVault'

import Button from '../../components/Button'
import { useEffect, useState } from 'react';
const web3 = createAlchemyWeb3('https://eth-mainnet.alchemyapi.io/v2/G83qYJR9QvStrWukW3JD6R0y3ukUJGlw')

function Profile() {
  let history = useHistory()
  const { user, visitor, menuVisible, stake } = useSelector(state => state.app)
  const dispatch = useDispatch()
  const [duxCoins, setDuxCoins] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      let tempCoins = String(await getDuxCoins()).split('.')
      setDuxCoins([tempCoins[0], tempCoins[1]])
    }
    fetchData()
  }, [])

  function setPaymentModal(boolean) {
    dispatch({ type: 'CHANGE_PAYMENT_MODAL', payload: boolean })
  }

  // useEffect(() => {
  //   if (!visitor) {
  //     if (user.dux === 0) {
  //       setWduxCoins([0, 0])
  //     }
  //     else {
  //       let tempCoins = String(user.dux.toFixed(1)).split('.')
  //       setWduxCoins([tempCoins[0], tempCoins[1]])
  //     }
  //   }
  // }, [user])

  async function getDuxCoins() {
    if (!visitor) {
      // let contractAddress = '0x5664766bc83ff93128b6a8519b8a9c3de4929684'

      // Get contract interface from ABI
      // const nftContract = new web3.eth.Contract(ABIDux, contractAddress)
      // let balance = await nftContract.methods.balanceOf(user.address).call()
      console.log(Number(web3.utils.fromWei(user.balance.toString(), 'ether')).toFixed(1))
      return Number(web3.utils.fromWei(user.balance.toString(), 'ether')).toFixed(1)
    }
    else return 0.0
  }
  
  function getDecimals(number, fraction) {
    let n = number;
    const decimalStr = n.toString().split('.')[1];
    let fract = n * 10 % 10 /10;
    let integr = n - fract;

    if (fraction) {
      if (decimalStr > 0) { 
        return decimalStr 
      } else {
        return 0
      }
    } else {
      return integr
    }
  }

  function handleLogout() {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('visitor')
    axios.defaults.headers.common['authorization'] = ''
    dispatch({ type: 'CHANGE_USER', payload: null })
    dispatch({ type: 'CHANGE_VISITOR', payload: false })
    history.push('/login')
  }

  function handleClickOpenDataVaultModal() {
    dispatch({ type: "CHANGE_DATA_VAULT_MODAL", payload: true })
  }

  function closeMenu() {
    dispatch({ type: 'CHANGE_MENU', payload: '' })
  }

  return (
    <div className={menuVisible === 'profile' ? 'profile profile--visible' : 'profile'}>
      <div className="profile__close">
        <X size={30} onClick={() => closeMenu()} />
      </div>
      <div className='profile__header'>
        <div className="profile__header-container">
          <div className='profile__picture-image'>
            <img src={profileImage} alt='profile pic' style={{ filter: !visitor ? '' : 'grayscale(1)' }} />
          </div>
          <div className='profile__picture-user'>
            <div className='profile__name'>
              {!visitor ? user?.username : 'Visitante'}
            </div>
            <div className='profile__wallet'>
              <img src={metamask} alt='metamask' style={{ height: '16px' }} />
              <p>{user.address}</p>
            </div>
          </div>
        </div>

        <h3 className="profile__balance-title">Available balance</h3>
        <div className='profile__balance-icon'>
          <div className="profile__balance-token">
            <span>
              <img src={duxYellowLogo} alt='dux yellow logo' />
            </span>
            $DUX
          </div>
          <div className='profile__balance-value'>
            {!visitor && user.balance > 0 ? Number(web3.utils.fromWei(user.balance.toString(), 'ether')).toLocaleString('en-IN', {minimumFractionDigits: 1, maximumFractionDigits: 5}) : 0}
          </div>
        </div>

        <h3 className="profile__balance-title">Invested balance</h3>
        <div className='profile__balance-icon'>
          <div className="profile__balance-token">
            <span>
              <img src={duxYellowLogo} alt='dux yellow logo' />
            </span>
            $DUX
          </div>
          <div className='profile__balance-value'>
            {!visitor && stake?.amount > 0 ? Number(web3.utils.fromWei(stake?.amount.toString(), 'ether')).toLocaleString('en-IN', {minimumFractionDigits: 1, maximumFractionDigits: 5}) : 0}
            {/* {!visitor && user.invested > 0 ? getDecimals(user.invested, false) : 0}.{!visitor && user.invested > 0 ? getDecimals(user.invested, true) : 0} */}
          </div>
        </div>
      </div>

      <div className='profile__actions'>
        <Button type={11} onClick={() => setPaymentModal('staking')}>Staking</Button>
        <Button type={10} onClick={() => setPaymentModal('investments')}>Track returns</Button>
        <Button type={8}>Connect my networks</Button>
        {/* <Button type={10} onClick={handleClickOpenDataVaultModal}>Conecte suas redes</Button> */}
        <Button type={8}>Rewards history</Button>
        <Button type={8}>Withdraw</Button>
      </div>
      {/* <div className='profile__logout' onClick={() => handleLogout()}>
        <Button type={3}>Sair</Button>
      </div> */}
      <div className='profile__protocol'>
        <img src={lumxLogo} alt='lumx logo' />BUILT ON LUMX PROTOCOL
      </div>
    </div>
  );
}

export default Profile;
