import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import './index.scss'

import Button from '../../components/Button'
import { getGreeting } from '../../constants/others'
import Carousel from './components/Carousel';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import LoginModal from '../../containers/LoginModal';
import axios from 'axios';
import { notifyError } from '../../helpers/errors'
// import { getAllOrganizations } from '../../api'
import PaymentModal from '../../components/PaymentModal';
import bannerWelcome from '../../assets/banner-gif.gif'
import cardImage from '../../assets/journey.png'
import Roadmap from '../../assets/tutorials/roadmap.pdf'

function Welcome() {
  let history = useHistory()
  const dispatch = useDispatch()
  const { user, visitor } = useSelector(state => state.app)
  const { benefits, opportunities, education } = useSelector(state => state.contents)
  const [loading, setLoading] = useState(true)
  // const [organizations, setOrganizations] = useState([])
  const journey = [
    { name: 'Invest $DUX', description: 'Stake DUX token to earn up to 100% in the future!', link: '' }, 
  ]
  const roadmap = [
    { name: 'Week 1', description: 'Your first steps inside our platform! Stake and explore!', link: Roadmap }, 
    { name: 'Week 2', description: 'Create journeys, interact with the DUX community through the network and earn for it!', link: '', unavailable: true },
  ]

  useEffect(() => {
    dispatch({ type: 'CHANGE_PAGE', payload: 'welcome' })
    // getAllOrganizations().then(response => {
    //   console.log(response)
    //   setOrganizations(response.data)
    // })

    axios.get('/content-card/all')
      .then(response => {
        let { data } = response
        let education = [], benefits = [], opportunities = []
        data.map(item => {
          let category = item.Category
          if (category.name === 'Educação') {
            education.push(item)
          }
          else if (category.name === 'Oportunidades') {
            opportunities.push(item)
          }
          else {
            benefits.push(item)
          }
        })
        dispatch({ type: 'CHANGE_BENEFITS', payload: benefits })
        dispatch({ type: 'CHANGE_OPPORTUNITIES', payload: opportunities })
        dispatch({ type: 'CHANGE_EDUCATION', payload: education })
        setLoading(false)
      }).catch(e => {
        console.log(e)
      })
  }, [])

  function setPaymentModal(modal) {
    dispatch({ type: 'CHANGE_PAYMENT_MODAL', payload: modal })
  }

  function handleCarrouselClick(content) {
    dispatch({ type: 'CHANGE_SELECTED_CONTENT', payload: content })
    dispatch({ type: 'CHANGE_PAGE', payload: 'contents' })
    history.push('/contents')
  }

  function handleClick(url) {
    dispatch({ type: 'CHANGE_PAGE', payload: url })
    history.push(`/${url}`)
    dispatch({ type: 'CHANGE_SELECTED_CONTENT', payload: url })
  }  

  return (
    loading ?
      <div className='welcome__loading'>
        <BounceLoader color='#FFAE00' />
      </div>
      :
      <div className={visitor ? 'welcome welcome--visitor' : 'welcome'}>
        <div className="login-modal__container">
          <LoginModal visitor={visitor} />
        </div>      
        <Header title={visitor ? 'Hello visitor!' : `Hello ${user.username}!`} greeting={false} subTitle='Welcome to DUX, an opportunity platform, we are happy to receive you!' />
        <img className='welcome__banner' src={bannerWelcome} alt='banner welcome' />
        {/* <Carousel data={organizations} organization title='Organizações' description='Agora você pode realizar missões por organização. Clique abaixo e navegue pelas organizações parceiras DUX.' text='Veja todas as organizações' onClick={() => handleClick('organizations')} visitor={visitor} /> */}
        {/* <Carousel mission={true} data={opportunities} title='Missões' description='Embarque na economia do futuro desbloqueando missões únicas.' text='Veja missões disponíveis.' onClick={() => handleCarrouselClick('opportunities')} visitor={visitor} /> */}
        {/* <Carousel embed={true} data={education} title='Jornada' description='Conteúdos semanais sobre cripto e GamiFi. Acesse informações seguras e feitas por especialistas.' text='Ver conteúdos disponíveis.' onClick={() => handleCarrouselClick('education')} visitor={visitor} /> */}
        {/* <Carousel data={benefits} title='Benefícios' description='Ganhe benefícios exclusivos para membros. Entre eles: Gift cards, cupons de desconto, produtos de parceiros e muito mais.' reverse={true} text='Ver benefícios disponíveis.' onClick={() => handleCarrouselClick('benefits')} visitor={visitor} />  */}
        <Carousel bg={2} img_url={cardImage} buttonText={'Complete journey'} unavailable data={journey} onClick={() => setPaymentModal('staking')} title='Recommended journeys' description='On the DUX platform, it is possible to complete journeys to earn tokens, badges and progress to different levels. At each level, you will be rewarded in some way. At this first moment, complete the journey below and officially become a DUX founder holder.' text='See all journeys' visitor={visitor} />
        <Carousel bg={3} buttonText={'Read more'} embed={true} data={roadmap} title='Roadmap and updates' description={`Follow all the steps of the platform's evolution here and stay informed about the next steps.`} onClick={() => handleClick()} visitor={false} />
      </div>
  )
}

export default Welcome;