export default function embed(state = {
  embedContent: '',
  selectedMission: '',
}, action) {
  switch (action.type) {
    case 'CHANGE_EMBED_CONTENT':
      return { ...state, embedContent: action.payload }
    case 'CHANGE_SELECTED_MISSION':
      return { ...state, selectedMission: action.payload }
    default:
      return state
  }
}
