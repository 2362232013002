import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { BounceLoader, PulseLoader } from 'react-spinners'
import _ from 'lodash'
import axios from 'axios'
import { Filter, X } from 'react-feather'
import './index.scss'

import Header from '../../components/Header';

function Airdrops() {
  let history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(null)
  const { id } = useParams()

  const { user, visitor } = useSelector(state => state.app)
  const { benefits } = useSelector(state => state.contents)

  useEffect(() => {
    if (_.isEmpty(benefits)) {
      setLoading(true)
      let education = [], benefits = [], opportunities = []
      axios.get('/content-card/all')
        .then(response => {
          let { data } = response

          data.map(item => {
            let category = item.Category
            if (category.name === 'Educação') {
              education.push(item)
            }
            else if (category.name === 'Oportunidades') {
              opportunities.push(item)
            }
            else {
              benefits.push(item)
            }
          })
          dispatch({ type: 'CHANGE_BENEFITS', payload: benefits })
          dispatch({ type: 'CHANGE_OPPORTUNITIES', payload: opportunities })
          dispatch({ type: 'CHANGE_EDUCATION', payload: education })
          benefits.map(item => {
            if (item.id === id) {
              setValue(item)
            }
          })
        }).catch(e => {
          console.log(e)
        })
    }
    else {
      benefits.map(item => {
        if (item.id === id) {
          setValue(item)
        }
      })
    }
  }, [])



  return (
    [!visitor && <div className='airdrops'>
      {value && <Header title={value.name} greeting={false} subTitle={value.airdrop_description} />}
      <textarea className='airdrops__steps' value={value ? value.steps : 'Conteúdo não encontrado'} disabled={true} />
    </div >]
  )
}

export default Airdrops;