import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss'

import Header from '../../components/Header';

function Embed() {
  const dispatch = useDispatch()
  const { embedContent } = useSelector(state => state.embed)
  const { visitor } = useSelector(state => state.app)

  useEffect(() => {
    dispatch({ type: 'CHANGE_SELECTED_CONTENT', payload: 'education' })
  }, [])

  return (
    [!visitor && <div className='embed'>
        {embedContent && <>
          <Header title={embedContent.name} greeting={false} subTitle={embedContent.description} />
          <iframe title="embed title" src={embedContent.link + "#toolbar=0"} />
        </>}
      </div>]
  )
}

export default Embed