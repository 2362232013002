import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import './index.scss'

import Header from '../../components/Header'
import Carousel from '../../containers/Welcome/components/Carousel'

import cardImage from '../../assets/holder.png'
import Metamask from '../../assets/tutorials/create_metamask.pdf'
import MetamaskToken from '../../assets/tutorials/tokens_metamask.pdf'
import MEXC from '../../assets/tutorials/mexc.pdf'
import Bit2Me from '../../assets/tutorials/bit2me.pdf'
import Roadmap from '../../assets/tutorials/roadmap.pdf'

const Holder = () => {
  let history = useHistory()
  const dispatch = useDispatch()
  const holderCarouselDescription = <p style={{ marginBottom: '0px' }}>On the DUX platform, it is possible to complete journeys to earn tokens, badges, and progress to different levels. At each level, you will be rewarded in some way. At this first moment, complete the journey below and officially become a <span style={{ fontWeight: '700' }}>DUX founder holder</span>!</p>
  const holder = [
    { name: 'How to create a metamask?', description: 'Learn how to create a metamask crypto wallet!', link: Metamask }, 
    { name: 'How to add network and tokens in metamask?', description: 'Learn how to add different networks and tokens in your metamask.', link: MetamaskToken }, 
    { name: 'How to buy and sell tokens on MEXC?', description: 'Learn basic operations and how to withdraw on MEXC exchange.', link: MEXC }, 
    { name: 'How to buy and sell tokens on Bit2Me?', description: 'Learn basic operations and how to withdraw on Bit2Me exchange.', link: Bit2Me },
  ]
  const roadmap = [
    { name: 'Week 1', description: 'Your first steps inside our platform! Stake and explore!', link: Roadmap }, 
    { name: 'Week 2', description: 'Create journeys, interact with the DUX community through the network and earn for it!', link: '', unavailable: true },
  ]

  function handleClick(url) {
    dispatch({ type: 'CHANGE_PAGE', payload: url })
    history.push(`/${url}`)
    dispatch({ type: 'CHANGE_SELECTED_CONTENT', payload: url })
  }

  return (
    <div className='holder'>
      <Header title={'Holder space'} subTitle={'Here you will have access to the most necessary information to discover all the platform functionalities and future features.'} />
      <div className="holder__cards">
        <Carousel embed bg={2} img_url={cardImage} buttonText={'Access content'} data={holder} title='Learn with us!' description={holderCarouselDescription} onClick={() => handleClick(Metamask)} visitor={false} />
        <Carousel bg={3} buttonText={'Read more'} embed={true} data={roadmap} title='Roadmap and updates' description={`Follow all the steps of the platform's evolution here and stay informed about the next steps.`} onClick={() => handleClick()} visitor={false} />
      </div>
    </div>
  )
}

export default Holder