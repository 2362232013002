import React, { useEffect, useState } from "react";
import { PulseLoader } from 'react-spinners'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { sha3_256 } from 'js-sha3';
import axios from "axios";
import { notifyError } from "../../helpers/errors";
import { validateEmail } from '../../constants/validate'
import './index.scss'

import Button from '../../components/Button'
import Input from "../../components/Input";
import Terms from '../../assets/terms.pdf'
import Privacy from '../../assets/privacy.pdf'

const NewUser = () => {
  let history = useHistory()
  const dispatch = useDispatch()
  const { newUser, address } = useSelector(state => state.app)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState(`${Math.floor(Math.random() * 10000000)}@gmail.com`)
  const [state, setState] = useState('AM')
  const [city, setCity] = useState('N/A')
  const [isChecked, setIsChecked] = useState(false)

  function toggleChecked() {
    setIsChecked(!isChecked)
  }

  // useEffect(() => {
  //   let selectStates = []
  //   let selectCities = []

  //   allStates.map(item => {
  //     selectStates.push({ value: item.name, label: item.name })
  //     item.cities.map(city => {

  //       if (selectCities[item.name]) {
  //         selectCities[item.name].push({
  //           value: city,
  //           label: city
  //         })
  //       }
  //       else {
  //         selectCities[item.name] = [{
  //           value: city,
  //           label: city
  //         }]
  //       }

  //     })
  //   })

  //   setStates(selectStates)
  //   setCities(selectCities)
  // }, [])

  async function saveNewUser() {

    if (validateFields()) {
      setLoading(true)
      axios.post('/user/create', { username: name, email, state, city, address, avatar: '' })
        .then(response => {
          const { account, token } = response.data
          localStorage.setItem('user', JSON.stringify(account))
          localStorage.setItem('token', token)
          axios.defaults.headers.common['authorization'] = `Bearer ${token}`
          dispatch({ type: 'CHANGE_VISITOR', payload: false })
          dispatch({ type: 'CHANGE_USER', payload: account })
          history.push('/welcome')
          dispatch({ type: 'CHANGE_NEW_USER', payload: false })
          setLoading(false)
        }).catch(e => {
          console.log(e)
          setLoading(false)
        })
    }
  }

  function validateFields() {
    if (name.length === 0) {
      notifyError('Nome é obrigatório')
      return false
    }

    if (!isChecked) {
      notifyError('Terms agreement')
      return false
    }

    // if (email.length === 0 || !validateEmail(email)) {

    //   notifyError('Email inválido')
    //   return false
    // }

    // if (state.length === 0) {
    //   notifyError('Estado é obrigatório')
    //   return false
    // }

    // if (city.length === 0) {
    //   notifyError('Cidade é obrigatório')
    //   return false
    // }

    return true
  }
  return (
    <div className={newUser ? 'new-user new-user--visible' : 'new-user'}>
      <div className="new-user__modal">
        <header>Welcome!</header>
        <h3>How can we address you?</h3>
        <p>We will not use your personal data.</p>
        <Input type={2} style={{ marginBottom: 0 }} placeholder='Username' value={name} onChange={setName} />
        {/* <Input type={3} placeholder='Insira seu email' value={email} onChange={setEmail} />
        <Input type={3} placeholder='Insira seu estado' value={state} onChange={setState} />
        <Input type={3} placeholder='Insira sua cidade' value={city} onChange={setCity} /> */}
        <div className="new-user__terms">
          <label className="new-user__checkbox">
            <p>I read and accepted the <span><a target='_blank' rel='noreferrer' href={Terms}>terms of use</a></span> and <span><a target='_blank' rel='noreferrer' href={Privacy}>privacy policy</a></span>.</p>
            <input type="checkbox" checked={isChecked} />
            <span className="checkmark" onClick={() => toggleChecked()} />
          </label>
        </div>        
        <Button type={2} onClick={() => !loading && saveNewUser()} >{loading ? <PulseLoader size={10} /> : 'Start'}</Button>
        {/* <select className="new-user__select">
          <option value="" disabled selected>Selecione seu estado</option>
        </select> */}
      </div>
    </div>
  )
}

export default NewUser