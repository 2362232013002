export default function app(state = {
  showModalDataVault: false,
  connected: {
    google: false,
    twitter: false,
    instagram: false,
    facebook: false,
    tiktok: false,
  }
}, action) {
  switch (action.type) {
    case 'CHANGE_DATA_VAULT_MODAL':
      return { ...state, showModalDataVault: action.payload }
    case 'CHANGE_DATA_VAULT_CONNECTED':
      return {
        ...state, connected: {
          ...state.connected,
          [action.payload.key]: action.payload.value
        }
      }
    default:
      return state
  }
}
