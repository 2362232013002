export default function form(state = {
  loginStep: 'login',
}, action) {
  switch (action.type) {
    case 'CHANGE_LOGIN_STEP':
      return { ...state, loginStep: action.payload }
    default:
      return state
  }
}
