import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { X, Copy, Download, Check, BarChart } from 'react-feather'
import { buyDuxCoin, verifyPixPayment, convertCoinToBRL, approveAccount, checkMinedTransaction, executeStaking } from 'api'
import { PulseLoader } from 'react-spinners'
import BigNumber from 'bignumber.js';
import './index.scss'

import { notifyError } from '../../helpers/errors';
import { showTooltip, formatBRLNumber, formatPriceBRL } from 'helpers'
import { onlyNumber } from '../../constants/validate'

import duxLogoBlack from '../../assets/logos/duxLogoBlack.svg'
import { ReactComponent as PIX } from '../../assets/pix.svg'
import Terms from '../../assets/staking.pdf'
import Button from '../Button'
import Input from '../Input'
import {stakeAbi} from "../../assets/contracts/stakeAbi"
import {ABIDux} from "../../assets/contracts/dux"
import { Biconomy } from '@biconomy/mexa'
import { ethers } from "ethers";
import { createAlchemyWeb3 } from "@alch/alchemy-web3"
import env from '../../env'
const web3 = createAlchemyWeb3('https://eth-mainnet.alchemyapi.io/v2/G83qYJR9QvStrWukW3JD6R0y3ukUJGlw')

function PaymentModal() {
  const dispatch = useDispatch()
  const { user, paymentModal, stake } = useSelector(state => state.app)
  const [mintId, setMintId] = useState('')
  const [pixQRCodeURL, setPixQRCodeURL] = useState('')
  const [pixCode, setPixCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [copied, setCopied] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState('')
  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState(-1)
  const [amount, setAmount] = useState('')
  const [coinAmount, setCoinAmount] = useState('')
  const [noMetamask, setNoMetamask] = useState(false)
  const [provider, setProvider] = useState('')
  // const [coinPrice, setCoinPrice] = useState('')
  // const [tduxToCurrency, setTduxToCurrency] = useState('')
  // const [loadingCoinValue, setLoadingCoinValue] = useState(false)
  const [pixFee, setPixFee] = useState(5)
  let pixTimeLimit = 300 // 300 seconds = 5 minutes
  const [pixTimeLeft, setPixTimeLeft] = useState(pixTimeLimit)
  let periods = ['3 months', '6 months', '1 year', '2 years']
  let waitingForResponse
  const contractAddressStake = env === 'production' ? "0x2B0F7DF87a0D0c705b28EE3E67cc2B9646F33870" : "0x514adcAE6f88d56E6c20CAb2ce118FDD28147643"
  const contractAddressToken = env === 'production' ? "0x623EBdA5fc6B271DD597E20ae99927eA9eF8515e" : "0x5Aec6c004E53914497A18c62Dc62a98863cB694E"
  const rawValue = stake ? new BigNumber(web3.utils.fromWei(stake?.amount, 'ether')) : 0
  let today = Date.now()/1000
  let investedValue = rawValue > 0 ? rawValue.toLocaleString('en-IN', { minimumFractionDigits: 1, maximumFractionDigits: 4 }) : 0
  
  let maxStakingDays = convertSecondsToDays(periodTimeInSeconds(parseInt(stake?.stakingDuration)))
  let initialValue = investedValue
  let projectedIncome = stake ? rawValue.times(periodAPR(parseInt(stake?.stakingDuration))) : 0
  
  let daysElapsed = (convertSecondsToDays(today - (parseInt(stake?.start))) >= maxStakingDays) ? maxStakingDays : convertSecondsToDays(today - (parseInt(stake?.start)))
  let yieldPerDay = projectedIncome/maxStakingDays
  let yieldedSoFar = yieldPerDay*daysElapsed

  let totalBalance = parseInt(rawValue)+yieldedSoFar

  useEffect(() => {
    if (window.ethereum) {
      setProvider(new ethers.providers.Web3Provider(window.ethereum, 'any'))
    } else {
      setNoMetamask(true)
    }
  }, [])

  function setPaymentModal(boolean) {
    dispatch({ type: 'CHANGE_PAYMENT_MODAL', payload: boolean })
  }

  function percentageIncrease(previousValue, currentValue) { 
    let increase = ((currentValue - previousValue) / previousValue)
    if (isNaN(increase)) {
      return null
    } else { 
      return '+' + increase.toLocaleString('pt-BR', { style: 'percent', currency: 'BRL', minimumFractionDigits: 0, maximumFractionDigits: 2 }); 
    }
  }

  function closeModal() {
    setCoinAmount('')
    setAmount('')
    setIsChecked(false)
    setSelectedPeriod('')
    setSelectedPeriodIndex(-1)
    setPaymentModal('')
  }

  function getPeriod(period, index) {
    setSelectedPeriod(period)
    setSelectedPeriodIndex(index)
  }

  function handleStaking() {
    let transaction 
    if (coinAmount > user.balance) {
      notifyError('Not enough balance')
    } else {
      setLoading(true)
      approveAccount(coinAmount, user.address).then(response => {
      // approveAccount(coinAmount, "0x9acc48786e17415a3F9633031C8a3A2087C412F9").then(response => {
        const interval = setInterval(() => {
        if (transaction !== "1") {
            checkMinedTransaction(response.data.transactionHash).then(response => {
              transaction = response.data.result.status
            })
          } else {
            clearInterval(interval)
            executeStaking(user.address, "stakeToken", "0xD2EFF01E4DAf5eeECFf4961dD3d2E39A3238F993", parseInt(coinAmount), selectedPeriodIndex).then(response => {
            // executeStaking("0x9acc48786e17415a3F9633031C8a3A2087C412F9", "stakeToken", "0xD2EFF01E4DAf5eeECFf4961dD3d2E39A3238F993", parseInt(coinAmount), selectedPeriodIndex).then(response => {
              console.log(response)
              setLoading(false)
              setPaymentModal('invested')
            })
          }
        }, 5000);  
      })
    }
  }

  // async function handleStakeMM() {
  //   const biconomy = new Biconomy(window.ethereum, {
  //     apiKey: "HRUXOR4f7.d919f73e-af37-4238-826a-1cba3a87ee49",
  //     debug: true,
  //     contractAddresses: ["0x5Aec6c004E53914497A18c62Dc62a98863cB694E", "0x9125B7bD068c021E9DC706DCeBe1B0069dFa78F0"], // list of contract address you want to enable gasless on
  //   });
    
  //   const provider = await biconomy.provider;

  //   const contractInstance = new ethers.Contract(
  //     "0x9125B7bD068c021E9DC706DCeBe1B0069dFa78F0",
  //     stakeAbi,
  //     biconomy.ethersProvider
  //   )

  //   await biconomy.init();
  //   console.log(contractInstance)
  //   const { data } = await contractInstance.populateTransaction.stakeToken(1,0)
  //   const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
  //   console.log(accounts )
  //   let txParams = {
  //     data: data,
  //     to: "0x9125B7bD068c021E9DC706DCeBe1B0069dFa78F0",
  //     from: accounts[0],
  //     signatureType: "EIP712_SIGN",
  //   }
  //   console.log(txParams)

  //   await provider.send("eth_sendTransaction", [txParams]);
  // }

  function checkBalance() {
    if (coinAmount > user.balance) {
      notifyError('Not enough balance')
    } else {
      setPaymentModal('confirm')
    }
  }

  async function handleStakeMM() {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
    const signer = provider.getSigner()

    const contractStake = new ethers.Contract(contractAddressStake, stakeAbi, signer)

    const contractDux = new ethers.Contract(contractAddressToken, ABIDux, signer)

    const web3 = createAlchemyWeb3('https://eth-mainnet.alchemyapi.io/v2/G83qYJR9QvStrWukW3JD6R0y3ukUJGlw')
    
    const valueWei = web3.utils.toWei(coinAmount,"ether" )

    try {
      setLoading(true)
      const transaction = await contractDux.approve(contractAddressStake, valueWei)

      await transaction.wait()
    } catch (err) {
      console.log(err)
    }


    try {
      setLoading(true)
      const transaction = await contractStake.stakeToken(valueWei, selectedPeriodIndex, {
        value: 0,
      })

      await transaction.wait()
      setPaymentModal('invested')
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  async function handleClaimMM() {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
    const signer = provider.getSigner()

    const contractStake = new ethers.Contract(contractAddressStake, stakeAbi, signer)

    try {
      setLoading(true)
      const transaction = await contractStake.claimReward()

      await transaction.wait()
    } catch (err) {
      console.log(err)
    }
  }

  function sendPixPayment() {
    setLoading(true)
    buyDuxCoin(user.address, user.id, parseInt(amount))
    .then(response => {
      console.log(response)
      setMintId(response.data.pix.mintId)
      setPixQRCodeURL(response.data.pix.qrcode)
      setPixCode(response.data.pix.brCode.split('=')[1])
      setLoading(false)
      setPaymentModal('pix')
    }).catch(e => {
      console.log(e)
    })
  }

  const verifyPixStatus = () => {
    waitingForResponse = true
    verifyPixPayment(mintId, user.id)
    .then(response => {
      if (response.data.status === 'COMPLETED') {
        setPaymentModal('finish')
      }
    })
    .catch((e) => {
      console.error(JSON.stringify(e))
    })
    .finally(() => {
      waitingForResponse = false
    })
  }  

  useEffect(() => {
    if (paymentModal === 'pix') {
      if (pixTimeLeft > 0) {
        setTimeout(() => {
          setPixTimeLeft(pixTimeLeft - 1)
        }, 1000)
      }
      if (pixTimeLeft >= 5 && pixTimeLeft % 5 === 0) {
        if (!waitingForResponse) verifyPixStatus() 
      }
    } else {
      setPixTimeLeft(pixTimeLimit)
    }
  }, [paymentModal, pixTimeLeft])  


  // useEffect(() => {
  //   setLoadingCoinValue(true)
  //   convertCoinToBRL().then(response => {
  //     setTduxToCurrency(response.data.price)
  //     setLoadingCoinValue(false)
  //   })

  //   const interval = setInterval(() => {
  //     if (paymentModal === 'staking') { 
  //       setLoadingCoinValue(true)
  //       convertCoinToBRL().then(response => {
  //         setTduxToCurrency(response.data.price)
  //         setLoadingCoinValue(false)
  //       })
  //     }
  //   }, 300000);
  
  //   return () => clearInterval(interval)
  // }, [coinAmount, paymentModal])

  function modalTitle(step) {
    switch (step) {
      case 'staking': return 'Invest $DUX'
      case 'investments': return 'Your investments'
      case 'finish': return 'Congratulations!'
      case 'invested': return 'Congratulations!'
      case 'confirm': return 'Are you sure?'
      case 'buy': return 'Buy $DUX'
      case 'pix': return 'Buy $DUX'
      default: return 'Buy $DUX'
    }
  }

  function modalContent(step) {
    switch (step) {
      case 'pix': return paymentStep()
      case 'invested': return investedStep()  
      case 'staking': return stakingStep()
      case 'investments': return investmentsStep()
      case 'finish': return finishStep()
      case 'buy': return buyCoins()
      case 'confirm': return confirmStep()
      default: return paymentStep()
    }
  }  

  function periodLineWidth(periodIndex) {
    switch (periodIndex) {
      case 1: return '32%'
      case 2: return '62%'
      case 3: return '89%'
      default: return '0%'
    }
  }  

  function periodTimeInSeconds(periodIndex) {
    switch (periodIndex) {
      case 1: return 15780000
      case 2: return 31536000
      case 3: return 63072000
      default: return 7890000
    }
  }  

  function convertSecondsToDays(seconds) {
    return Math.floor(seconds / 86400)
  }  

  function periodAPR(periodIndex) {
    switch (periodIndex) {
      case 0: return 0.0078
      case 1: return 0.03125
      case 2: return 0.25
      case 3: return 1
      default: return ''
    }
  }  

  function formatValue(value) {
    return Intl.NumberFormat('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 10 }).format(value)
  }

  const buyCoins = () => {
    return (
      <div className="payment-modal__insert-amount">
        <header>
          <Download />
          Insert amount
        </header>
        <p>Insira a quantidade de $DUX que você deseja comprar.</p>
        <div className="payment-modal__amount">
          <img src={duxLogoBlack} alt='dux logo' />
          <p>DUX</p>
          <Input type={2} wrapType={2} placeholder='0.00' onChange={setAmount} value={amount} format={onlyNumber} />
        </div>
        <div className="payment-modal__values">
          <div className="payment-modal__value">
            <p>Valor depositado</p>
            <p>{amount ? formatPriceBRL(parseInt(amount))  : formatPriceBRL(0)}</p>
          </div>
          <div className="payment-modal__value">
            <p>Unidade $DUX</p>
            <p>{coinAmount}</p>
          </div>
        </div>
        <Button type={2} onClick={() => sendPixPayment()}>{loading ? <PulseLoader size={10} /> : 'Confirmar compra'}</Button>
      </div>
    )
  }
  
  const paymentStep = () => {
    return (
      <div className="payment-modal__pix">
        <div className="payment-modal__pix-title">
          <PIX />
          <h3>Pagamento PIX</h3>
        </div>
        <p>Complete o pagamento para criar a missão.</p>
        <div className="payment-modal__qrcode">
          <img src={pixQRCodeURL} alt="QR Code para pagamento com Pix" />
        </div>
        <div className="pix__container">
          <div className="pix__code">
            <p>{pixCode}</p>
          </div>
          <div className='pix__copy'
            onClick={() => {
              showTooltip(pixCode, setCopied)
            }}
          >
            <Copy />
            <span className={copied ? 'pix__tooltip pix__tooltip--visible' : 'pix__tooltip'}>
              Copiado!
            </span>
          </div>
        </div>
        <div className="payment-modal__values">
          <div className="payment-modal__value">
            <p>Valor depositado</p>
            <p>{formatPriceBRL(parseInt(amount))}</p>
          </div>
          <div className="payment-modal__value">
            <p>Taxa de rede</p>
            <p>R$ {pixFee}</p>
          </div>
          <div className="payment-modal__value">
            <p>Valor total</p>
            <p>R$ {parseInt(amount) + pixFee}</p>
          </div>
        </div>
      </div>
    )
  }

  const finishStep = () => {
    return (
      <div className="payment-modal__pix">
        <div className="payment-modal__pix-title">
          <Check />
          <h3>Pagamento aprovado</h3>
        </div>
        <p>Complete o pagamento para adquirir $DUX.</p>
        <div className="payment-modal__values">
          <div className="payment-modal__value">
            <p>Valor depositado</p>
            <p>{formatPriceBRL(parseInt(amount))}</p>
          </div>
          <div className="payment-modal__value">
            <p>Taxa de rede</p>
            <p>{formatPriceBRL(pixFee)}</p>
          </div>
          <div className="payment-modal__value">
            <p>Valor total</p>
            <p>{formatPriceBRL(505)}</p>
          </div>
        </div>
        <Button type={2} onClick={() => setPaymentModal('')}>Voltar ao início</Button>
      </div>
    )
  }  

  const stakingStep = () => {
    return (
      <div className="payment-modal__insert-amount">
        <header>
          <BarChart />
          Earn up to 100% return
        </header>
        <p>Enter the amount of $DUX you will invest.</p>
        <div className="payment-modal__amount">
          <img src={duxLogoBlack} alt='dux logo' />
          <p>DUX</p>
          <Input inputMode={'decimal'} type={2} wrapType={2} placeholder='0.00' onChange={setCoinAmount} value={coinAmount.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')} />
        </div>
        {/* <div className="payment-modal__value">
          <p>Valor aproximado</p>
          <p>{loadingCoinValue ? 'Atualizando...' : coinAmount ? formatPriceBRL(parseInt(coinAmount*tduxToCurrency))  : formatPriceBRL(0)}</p>
        </div> */}
        <div className="payment-modal__period">
          <p>Period</p>
          <div className='periods'>
            <div className="periods__line" />
            <div className="periods__line-filled" style={{ width: periodLineWidth(selectedPeriodIndex) }} />
            {periods.map((period, i) => {
            return (
            <div className="period" key={period} onClick={() => getPeriod(period, i)}>
              <div className={(selectedPeriodIndex >= i) ? "period__selection period__selection--selected" : "period__selection"} />
              <p>{period}</p>
            </div>)})}
          </div>
        </div>
        <div className="payment-modal__value">
          <p>Actual gains</p>
          <p>{selectedPeriodIndex === 0 ? '0.78%' : periodAPR(selectedPeriodIndex)*100+'%'}</p>
        </div>
        <div className="payment-modal__terms">
          <label className="payment-modal__checkbox">
            <p>I understand that I can only redeem my $DUX tokens in the defined period and agree to the <span><a target='_blank' rel='noreferrer' href={Terms}>terms of investment</a></span>.</p>
            <input type="checkbox" checked={isChecked} />
            <span className="checkmark" onClick={() => setIsChecked(!isChecked)} />
          </label>
        </div>
        <div className="payment-modal__values">
          <div className="payment-modal__value">
            <p>Accumulated returns</p>
            <p>Total balance</p>
          </div>
          <div className="payment-modal__value">
            <p>{coinAmount*periodAPR(selectedPeriodIndex)} $DUX</p>
            <p>{coinAmount ? parseFloat(coinAmount)+coinAmount*periodAPR(selectedPeriodIndex) : 0} $DUX</p>
          </div>
        </div>
        <Button style={{ marginBottom: '0px' }} type={10} onClick={() => setPaymentModal('investments')}>Track my investments</Button>
        <Button style={{ marginBottom: '0px' }} type={(coinAmount && selectedPeriod && isChecked) ? 2 : 9} onClick={(coinAmount && selectedPeriod && isChecked) ? () => checkBalance() : null}>Confirm investiment</Button>
      </div>
    )
  }

  const investedStep = () => {
    return (
      <div className="payment-modal__pix">
        <div className="payment-modal__pix-title">
          <Check />
          <h3>$DUX invested</h3>
        </div>
        <p>Everything went well and your tokens are already earning!</p>
        <div className="payment-modal__investments">
          <div className="payment-modal__investment">
            <p>Invested value</p>
            <p>{formatBRLNumber(coinAmount)} $DUX</p>
          </div>
          <div className="payment-modal__investment">
            <p>Period</p>
            <p>{selectedPeriod}</p>
          </div>
        </div>
        <Button style={{ marginBottom: '0px' }} type={10} onClick={() => setPaymentModal('investments')}>Track my investments</Button>
        {/* <Button style={{ marginBottom: '0px' }} type={2} onClick={() => setPaymentModal('staking')}>Return to the start</Button> */}
      </div>  
    )
  }

  const investmentsStep = () => {
    return (
      <div className="payment-modal__insert-amount">
        <header>
          <BarChart />
          Your yield until now
        </header>
        <p>See how much your money yielded:</p>
        <div className="payment-modal__investments">
          <div className="payment-modal__investment">
            <p>Initial value</p>
            {formatValue(initialValue) + ' $DUX'}
          </div>
          <div className="payment-modal__investment">
            <p>Projected income</p>
            {formatValue(projectedIncome)  + ' $DUX'}
          </div>
          <div className="payment-modal__investment">
            <p>Yielded so far</p>
            {(isNaN(yieldedSoFar) ? 0 : formatValue(yieldedSoFar)) + ' $DUX'}
          </div>
          <div className="payment-modal__investment">
            <p>Total balance</p>
            <div className="payment-modal__increase">
              <span>{percentageIncrease(parseInt(initialValue), totalBalance)}</span>
              {(isNaN(totalBalance) ? 0 : formatValue(totalBalance)) + ' $DUX'}
            </div>
          </div>
        </div>
        {/* Uncomment to enable claim button */}
        <Button type={9}>Claim</Button>        
        {/* <Button type={2} onClick={() => handleClaimMM()}>Claim</Button>         */}
      </div>
    )
  }

  const confirmStep = () => {
    return (
      <div className="payment-modal__insert-amount">
        <p>By confirming your investment, you can only make a second investment by the end of the selected period.</p>
        <p><span>You can only invest again in:</span></p>
        <h3>{selectedPeriod}</h3>
        <Button style={{ marginBottom: '0px' }} type={2} onClick={loading ? null : () => handleStakeMM()}>{loading ? <div className='payment-modal__loading'>Carregando transação</div> : 'Confirm staking'}</Button>
      </div>
    )
  }

  return (
    <div className={paymentModal ? 'payment-modal payment-modal--visible' : 'payment-modal'}>
      <div className="payment-modal__modal">
        <header>
          { modalTitle(paymentModal) }
          <X onClick={() => closeModal()} />
        </header>
        { modalContent(paymentModal) }
      </div>
    </div>
  )
}

export default PaymentModal