export default function form(state = {
  step: 'Tipo de criação',
  formType: '',
  formSubType: '',
  categories: [],
  subCategories: [],
  levels: [],
  missions: [],
  chosenBadges: [],
  paymentModal: false
}, action) {
  switch (action.type) {
    case 'CHANGE_CREATION_STEP':
      return { ...state, step: action.payload }
    case 'CHANGE_FORM_TYPE':
      return { ...state, formType: action.payload }
    case 'CHANGE_FORM_SUBTYPE':
      return { ...state, formSubType: action.payload }
    case 'CHANGE_CATEGORIES':
      return { ...state, categories: action.payload }
    case 'CHANGE_SUBCATEGORIES':
      return { ...state, subCategories: action.payload }
    case 'CHANGE_LEVELS':
      return { ...state, levels: action.payload }
    case 'CHANGE_MISSIONS':
      return { ...state, missions: action.payload }
    case 'CHANGE_BADGES':
      return { ...state, chosenBadges: action.payload }
    case 'CHANGE_PAYMENT_MODAL':
      return { ...state, paymentModal: action.payload }
    default:
      return state
  }
}
