import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { sha3_256 } from 'js-sha3';
import axios from 'axios'
import { PulseLoader } from 'react-spinners'
import { notifyError } from "../../helpers/errors";
import './index.scss'

import duxLogoBlack from '../../assets/logos/duxLogoBlack.svg'
import lumxLogo from '../../assets/logos/lumxLogo.svg'
import metamask from '../../assets/icons/metamask.png'
import background from '../../assets/icons/sticker.png'
import Button from '../../components/Button'
import Input from '../../components/Input';

import { onlyNumberAndSixDigits, validateEmail } from '../../constants/validate'

function Login() {
  let history = useHistory()
  const dispatch = useDispatch()
  const { user, visitor } = useSelector(state => state.app)
  const [metamaskLoginLoading, setMetamaskLoginLoading] = useState(false)
  const [createAccountLoading, setCreateAccountLoading] = useState(false)
  const [loginLoading, setLoginLoading] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [newUser, setNewUser] = useState(false)
  const isLoading = createAccountLoading || loginLoading || metamaskLoginLoading

  async function login() {
    setMetamaskLoginLoading(true)
    const { ethereum } = window
    let address

    try {
      if (window.ethereum) {
        address = await ethereum.request({
          method: 'eth_requestAccounts',
        })
      } else {
        notifyError('Install Metamask')
        setMetamaskLoginLoading(false)
      }
    } catch (error) {
      setMetamaskLoginLoading(false)
    }

    try {
      if (address.length > 0) {
        const nonce = parseInt(await ethereum.request({
          method: 'eth_getTransactionCount',
          params: [address[0], "latest"]
        }), 16)
        const message = `A assinatura dessa mensagem tem por objetivo garantir um login seguro. Essa ação não causará nenhuma cobrança. \n\n Nonce: ${sha3_256(nonce.toString())}`

        const hash = await ethereum.request({ method: 'personal_sign', params: [message, address[0]] })
        let locale = 'pt'
        let chainId = 5

        axios.post('/user/login', { address: address[0], chainId, locale, hash })
          .then(response => {
            const { account, token } = response.data
            localStorage.setItem('user', JSON.stringify(account))
            localStorage.setItem('token', token)
            axios.defaults.headers.common['authorization'] = `Bearer ${token}`
            dispatch({ type: 'CHANGE_VISITOR', payload: false })
            dispatch({ type: 'CHANGE_USER', payload: account })
            history.push('/welcome')
            setMetamaskLoginLoading(false)
          }).catch(e => {
            if (e.response.data.message === 'Wallet address not found in database.') {
              dispatch({ type: 'CHANGE_ADDRESS', payload: address[0] })
              dispatch({ type: 'CHANGE_NEW_USER', payload: true })
            }
            else {
              notifyError(e.response.data.message)
              setMetamaskLoginLoading(false)
            }
          })
      }
    } catch (error) {
      setMetamaskLoginLoading(false)
    }
  }

  function handleVisitor() {
    dispatch({ type: 'CHANGE_VISITOR', payload: true })
    localStorage.setItem('visitor', JSON.stringify(true))
    history.push('/welcome')
  }

  async function createEvmUser() {
    setCreateAccountLoading(true)

    if (username.length === 0 || state.length === 0 || city.length === 0) {
      notifyError("All fields must be filleds")
      setCreateAccountLoading(false)
      return
    }

    if (password.length < 6) {
      notifyError("password must have 6 characteres")
      setCreateAccountLoading(false)
      return
    }

    if (password !== passwordConfirmation) {
      notifyError("passwords don't match")
      setCreateAccountLoading(false)
      return
    }

    if (!validateEmail(email)) {
      notifyError("Email inválido")
      setCreateAccountLoading(false)
      return
    }

    axios.post('/user/create', { username, address: null, password, avatar: null, city, email, state })
      .then(response => {
        const { account, token } = response.data
        localStorage.setItem('user', JSON.stringify(account))
        localStorage.setItem('token', token)
        axios.defaults.headers.common['authorization'] = `Bearer ${token}`
        dispatch({ type: 'CHANGE_VISITOR', payload: false })
        dispatch({ type: 'CHANGE_USER', payload: account })
        history.push('/welcome')
        setCreateAccountLoading(false)
      }).catch(e => {
        notifyError(e.response.data.message)
        setCreateAccountLoading(false)
      })
  }

  async function evmLogin() {
    setLoginLoading(true)
    axios.post('/session-evm', { username, password })
      .then(response => {
        const { account, token } = response.data
        localStorage.setItem('user', JSON.stringify(account))
        localStorage.setItem('token', token)
        axios.defaults.headers.common['authorization'] = `Bearer ${token}`
        dispatch({ type: 'CHANGE_VISITOR', payload: false })
        dispatch({ type: 'CHANGE_USER', payload: account })
        history.push('/welcome')
        setLoginLoading(false)
      }).catch(e => {
        notifyError(e.response.data.message)
        setLoginLoading(false)
      })
  }

  return (
    <div className='login'>
      <div className='login__right'>
        <img className='login__logo' src={duxLogoBlack} alt='dux logo' />
        {newUser ?
          <>
            <h3>Criar conta</h3>
            <div className='login__contents'>
              <Input type={2} placeholder='Insira seu email' onChange={setEmail} value={email} inputType='email' />
              <Input type={2} placeholder='Insira seu username' onChange={setUsername} value={username} />
              <Input type={2} placeholder='Insira sua senha' onChange={setPassword} value={password} password={true} format={onlyNumberAndSixDigits} />
              <Input type={2} placeholder='Repita sua senha' onChange={setPasswordConfirmation} value={passwordConfirmation} password={true} format={onlyNumberAndSixDigits} />
              <Input type={2} placeholder='Insira seu estado' onChange={setState} value={state} />
              <Input type={2} placeholder='Insira sua cidade' onChange={setCity} value={city} />
              <Button type={2} onClick={() => !isLoading && createEvmUser()}>
                {createAccountLoading ? <PulseLoader size={10} /> : 'Criar conta'}
              </Button>
              <h4>ou</h4>
              {/* <div className='login__visitor' onClick={() => handleVisitor()}>Continuar sem login</div> */}
              <div className='login__create'>Já tem conta? Clique <span onClick={() => setNewUser(false)}>aqui</span> para fazer login.</div>
            </div>
          </>
          :
          <>
            <div className='login__title'>
              Join the <span>DUX Revolution</span>
            </div>
            <div className='login__sub-title'>
              Future opportunities for today's challenges.
            </div>
            <div className='login__contents'>
              <Input type={2} placeholder='Insert username' onChange={setUsername} value={username} />
              <Input type={2} placeholder='Insert password' onChange={setPassword} value={password} password={true} format={onlyNumberAndSixDigits} />
              <Button type={2} onClick={() => !isLoading && evmLogin()}>
                {loginLoading ? <PulseLoader size={10} /> : 'Sign in'}
              </Button>
              <h4>or</h4>
              <Button style={{ marginTop: '10px' }} type={10} onClick={() => !isLoading && login()}>
                {metamaskLoginLoading ? <PulseLoader size={10} /> : <><img src={metamask} alt='metamask' style={{ height: '16px' }} />Sign in with Metamask</>}
              </Button>
              {/* <div className='login__visitor' onClick={() => handleVisitor()}>Continuar sem login</div> */}
              {/* <div className='login__create'>Não tem conta? <span onClick={() => setNewUser(true)}>Criar uma conta agora.</span></div> */}
            </div>
          </>
        }
        <span className='login__yellow-bar' />
      </div>
      <div className='login__left' />
      <div className='login__protocol'>
        <img src={lumxLogo} alt='lumx logo' />BUILT ON LUMX PROTOCOL
      </div>
    </div >
  );
}

export default Login;