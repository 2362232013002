export default function contents(state = {
  opportunities: [],
  benefits: [],
  education: [],
  selectedContent: 'opportunities',
}, action) {
  switch (action.type) {
    case 'CHANGE_OPPORTUNITIES':
      return { ...state, opportunities: action.payload }
    case 'CHANGE_BENEFITS':
      return { ...state, benefits: action.payload }
    case 'CHANGE_EDUCATION':
      return { ...state, education: action.payload }
    case 'CHANGE_SELECTED_CONTENT':
      return { ...state, selectedContent: action.payload }
    default:
      return state
  }
}
