import './index.scss'

const Steps = ({ steps, currentStep }) => {
  return (
    <div className='steps'>
      <div className="steps__line"></div>
      {steps.map((step, i) => {
      return (
      <div className="step">
        <div className={(currentStep === step) ? "step__number step__number--current" : "step__number"}>
          {i+1}
        </div>
        <p>{step}</p>
      </div>)})}
    </div>
  )
}

export default Steps