import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import './index.scss'

import Button from '../../../../components/Button'
import Carousel from '../../../Welcome/components/Carousel'

const MissionFlow = ({ onClick, nextStep }) => {
  const dispatch = useDispatch()
  const { visitor } = useSelector(state => state.app)
  const { benefits, opportunities } = useSelector(state => state.contents)
  const { missions } = useSelector(state => state.form)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch({ type: 'CHANGE_FORM_TYPE', payload: 'jornada' })
    axios.get('/content-card/all')
      .then(response => {
        let { data } = response
        let education = [], benefits = [], opportunities = []
        data.map(item => {
          let category = item.Category
          if (category.name === 'Educação') {
            education.push(item)
          }
          else if (category.name === 'Oportunidades') {
            opportunities.push(item)
          }
          else {
            benefits.push(item)
          }
        })
        dispatch({ type: 'CHANGE_BENEFITS', payload: benefits })
        dispatch({ type: 'CHANGE_OPPORTUNITIES', payload: opportunities })
        dispatch({ type: 'CHANGE_EDUCATION', payload: education })
        setLoading(false)
      }).catch(e => {
        console.log(e)
      })
  }, [])

  return (
    <>
      <div className={missions.length > 0 ? 'mission-flow__modal mission-flow__modal--visible' : 'mission-flow__modal'}>
        <div className="mission-flow__modal-text">
          {missions.length > 1 ? missions.length + ' jornadas adicionadas' 
          : '1 jornada adicionada'
          }
        </div>
        <div className="mission-flow__modal-button">
          <Button type={2} onClick={nextStep} >
            Ver jornada
          </Button>
        </div>
      </div>
      <div className="mission-flow__header">
        <div className="mission-flow__title">
          <h1>Adicionar Missão à Jornada</h1>
          <p>Você pode adicionar quantas missões você quiser.</p>
        </div>
        <div className="mission-flow__button">
          <Button type={1} onClick={onClick}>Criar uma nova missão</Button>
        </div>
      </div>
      <div className="mission-flow__missions">
        <Carousel addToList data={opportunities}  title='Missões' />
        <Carousel addToList data={benefits}  title='Benefícios' reverse={true} />
      </div>
    </>
  )
}

export default MissionFlow