import { useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom"
import _ from 'lodash'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './styles/index.scss'
import { getStakeData, getUserStaking } from 'api'

import Login from './containers/Login'
import Menu from "./containers/Menu"
import Profile from "./containers/Profile"
import UserProfile from "./containers/UserProfile"
import Welcome from "./containers/Welcome"
import Opportunities from "./containers/Opportunities"
import Warning from "./containers/Warning"
// import LoginModal from "./containers/LoginModal"
// import Mobile from './containers/Mobile'
import DataVaultModal from "./components/DataVault"
import Header from "./components/Header"
import NewUser from "./containers/NewUser"
import Payments from "./containers/Payments"
import Document from "./containers/Document"
import Airdrops from "./containers/Airdrops"
import Embed from "./containers/Embed"
import Form from "./containers/Form"
import Organizations from "./containers/Organizations"
import Mission from "./containers/Mission"
import Holder from "./containers/Holder"
import PaymentModal from "./components/PaymentModal"

function App() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { user, visitor, page, paymentModal } = useSelector(state => state.app)
  let loggedUser = localStorage.getItem('user')
  let loggedVisitor = localStorage.getItem('visitor')
  let token = localStorage.getItem('token')

  useEffect(() => {
    getStakeData().then(response => {
      dispatch({ type: 'CHANGE_STAKE_DATA', payload: response.data })
    })

    if (typeof (loggedUser) === 'string') {
      localStorage.removeItem('visitor')
      loggedUser = JSON.parse(loggedUser)
      axios.defaults.headers.common['authorization'] = `Bearer ${token}`
      dispatch({ type: 'CHANGE_USER', payload: loggedUser })
      axios.get(`/user/find/address/${loggedUser.address}`)
        .then(response => {
          dispatch({ type: 'CHANGE_USER', payload: response.data })
          localStorage.setItem('user', JSON.stringify(response.data))
          dispatch({ type: 'CHANGE_USER', payload: response.data })
        }).catch(e => {
          console.log(e)
        })
    } else {
      dispatch({ type: 'CHANGE_VISITOR', payload: true })
      localStorage.setItem('visitor', JSON.stringify(true))
    }

    if (typeof (loggedVisitor) === 'string') {
      loggedVisitor = JSON.parse(loggedVisitor)
      dispatch({ type: 'CHANGE_VISITOR', payload: loggedVisitor })
    }
  }, [])

  useEffect(() => {
    getUserStaking(user?.address).then(response => {
      console.log(response)
      dispatch({ type: 'CHANGE_STAKE', payload: response.data })
    })
  }, [user])

  return (
    <Router>
      <Switch>
        {(!user && !visitor) &&
          <>
            <NewUser />
            <Route exact path="/login">
              <Login />
            </Route>
            {/* <Route path="*">
              <Redirect to="/login" />
            </Route> */}
            {/* <Mobile /> */}
            <Route exact path="/document/terms">
              <Document />
            </Route>
            <Route exact path="/document/privacy">
              <Document />
            </Route>
            <Route exact path='/'>
              <Redirect to="/welcome" />
            </Route>
            <ToastContainer pauseOnHover={false} theme="dark" />
          </>
        }
        {(user || visitor) &&
          <>
            <NewUser />
            {/* <Warning /> */}
            <PaymentModal />
            {/* <Menu /> */}
            {page !== 'login' && <Menu />}
            {visitor ? null : <>
              <Profile />
              <Header mobile />
            </>}
            <Route exact path="/login">
              <Login />
            </Route>
            {/* <div className="login-modal__container">
              <LoginModal visitor={visitor} />
            </div> */}
            <DataVaultModal />
            <Route exact path="/welcome">
              <Welcome />
            </Route>
            <Route exact path="/holder">
              <Holder />
            </Route>
            <Route exact path="/myprofile">
              <UserProfile />
            </Route>
            <Route exact path="/contents">
              <Opportunities />
            </Route>
            <Route exact path="/payments">
              <Payments />
            </Route>
            <Route exact path="/document/terms">
              <Document />
            </Route>
            <Route exact path="/document/privacy">
              <Document />
            </Route>
            <Route exact path="/form">
              {visitor ? <Redirect to="/welcome" /> : <Form />}
            </Route>
            <Route exact path="/organizations">
              {visitor ? <Redirect to="/welcome" /> : <Organizations />}
            </Route>
            <Route path="/airdrops/:id">
              <Airdrops />
            </Route>
            <Route path="/education/:id">
              <Embed />
            </Route>
            <Route path="/mission/:id">
              <Mission />
            </Route>
            {/* <Route path="/">
              <Redirect to="/welcome" />
            </Route> */}
            {/* <Mobile /> */}
            <ToastContainer pauseOnHover={false} theme="dark" />
          </>
        }
      </Switch>
    </Router>
  );
}

export default App;
