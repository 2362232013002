import { useRef } from "react";
import { ArrowLeftCircle, ArrowRightCircle } from 'react-feather'
import { useDispatch, useSelector } from "react-redux";
import { useDraggable } from "react-use-draggable-scroll"
import Button from '../../../../components/Button'
import axios from 'axios'
import './index.scss'
import { useHistory } from "react-router-dom";
import profileImage from '../../../../assets/profileImage.png'

const Carousel = ({ data, title, description, reverse = false, text, onClick, visitor, img_url, embed = false, organization = false, mission = false, addToList = false, buttonText, bg, unavailable }) => {
  const ref = useRef()
  const history = useHistory()
  const { events } = useDraggable(ref)
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.app)
  const { missions } = useSelector(state => state.form)
  function setEmbedContent(content) {
    dispatch({ type: 'CHANGE_EMBED_CONTENT', payload: content })
  }
  
  function setSelectedMission(missionId) {
    dispatch({ type: 'CHANGE_SELECTED_MISSION', payload: missionId })
  }

  function openLink(item) {
    setEmbedContent(item)
    chooseContent(item.id, item.link, true)
  }

  function handleJourneyList(mission) {
    const index = missions.indexOf(mission)
    if (missions.indexOf(mission) > -1) {
      missions.splice(index, 1)
      dispatch({ type: 'CHANGE_MISSIONS', payload: missions })
    } else {
      missions.push(mission)
      dispatch({ type: 'CHANGE_MISSIONS', payload: missions })
    }
  }

  function handleInterest(item) {
    if (visitor) {
      return
    }

    if (embed) {
      setEmbedContent(item)
      dispatch({ type: 'CHANGE_PAGE', payload: 'education' })
      // history.push(`/education/${item.id}`)
      chooseContent(item.id, item.link, true)
    }
    if (mission) {
      setSelectedMission(item.id)
      dispatch({ type: 'CHANGE_PAGE', payload: 'mission' })
      history.push(`/mission/${item.id}`)
    }
    else {
      if (!visitor) {
        if (item.Category.name === 'Benefícios') {
          dispatch({ type: 'CHANGE_PAGE', payload: 'benefits' })
          history.push(`/airdrops/${item.id}`)
          chooseContent(item.id, item.link, false)
        }
        else {
          chooseContent(item.id, item.link, true)
        }
      }
    }
  }

  function chooseContent(id, link, open) {
    if (open) {
      window.open(link, '_blank')
    }

    axios.post('content-card/list/add', { contentCardId: id })
      .then(response => {
        fetchUserData()
      }).catch(e => {
        console.log(e)
      })
  }

  async function fetchUserData() {
    let userData = await axios.get(`/user/find/address/${user.address}`)
    dispatch({ type: 'CHANGE_USER', payload: userData.data })
    localStorage.setItem('user', JSON.stringify(userData.data))
  }

  return (
    <div className='carousel'>
      <div className={'carousel__container'}>
        <div className='carousel__cards-container' ref={ref} {...events}>
          {organization ? 
          data.map(item => {
            return (
              <div key={item.name} className='carousel__organization'>
                <img src={item.img_url ? item.img_url : profileImage} alt='organization pic' />
              {item.name}
            </div>)
          })
          : data.map(item => {
            return (
              <>
                <div key={item.name} className={bg ? `carousel__card carousel__card--bg-${bg}` : 'carousel__card'} style={{ backgroundImage: `url(${img_url ? img_url : item.img_url})` }}>
                  <span />
                  <header>{item.name}</header>
                  <h3>{item.description}</h3>
                  <div
                    className='carousel__card-button'
                    style={(item.unavailable || visitor) ? { opacity: '50%' } : {}}>
                    <Button type={4} style={{ height: 'unset', padding: '6px 0px', marginBottom: '0px', fontSize: '10px' }} onClick={visitor ? null : embed ? () => openLink(item) : onClick}>
                      {item.unavailable ? 'Coming soon' : buttonText ? buttonText : 'Access content'}
                    </Button> 
                    {/* {addToList ? 
                    <Button type={4} style={{ height: 'unset', padding: '6px 0px', marginBottom: '0px', fontSize: '10px' }} onClick={() => handleJourneyList(item)}>
                      {(missions.indexOf(item) > -1) ? 'Remover' : 'Adicionar'}
                    </Button> : onClick ? 
                    <Button type={4} style={{ height: 'unset', padding: '6px 0px', marginBottom: '0px', fontSize: '10px' }} onClick={onClick}>
                      {buttonText ? buttonText : 'Tenho Interesse'}
                    </Button> :
                    <Button type={4} style={{ height: 'unset', padding: '6px 0px', marginBottom: '0px', fontSize: '10px' }} onClick={() => handleInterest(item)}>
                      {buttonText ? buttonText : 'Tenho Interesse'}
                    </Button>} */}
                  </div>
                </div>
              </>
            )
          })}
        </div>
        <div className='carousel__description' >
          <header>{title}</header>
          {description && <p>{description}</p>}
          {text && <footer className={unavailable && 'carousel__description-unavailable'} onClick={onClick}>{text}<ArrowRightCircle /> {unavailable && <span>&nbsp;Coming Soon</span>}</footer>}
        </div>
      </div>
    </div>
  )
}

export default Carousel