export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
}

export function showTooltip(value, setState) {
  copyToClipboard(value)
  setState(true)
  setTimeout(() => {
    setState(false)
  }, 2000)
}

export function formatBRLNumber(number) {
  return new Intl.NumberFormat('pt-BR', { maximumSignificantDigits: 6 }).format(number);
}

export function formatPriceBRL(price) {
  return price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 0 });
}